import React, { useState, Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel
} from '@material-ui/core';

import { data_type_issue, data_luu_y } from './data';

const styles = theme => ({
  root: {}
});

class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type_issue: ''
    };
  }

  handleChange = text => {
    this.setState({
      type_issue: text
    });
  };

  clickNext = () => {
    this.props.clickNext(this.state.type_issue);
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Card className={classes.root}>
          <form autoComplete="off" noValidate>
            <CardContent>
              <Typography variant="h2" gutterBottom>
                Tạo đơn yêu cầu
              </Typography>
            </CardContent>
            <Divider />
            <CardContent>
              <Typography variant="h4" gutterBottom>
                Lưu ý:
              </Typography>
              {data_luu_y.map((item, index) => {
                return (
                  <Typography key={index} >{item}</Typography>
                )
              })}
            </CardContent>
            <Divider />
            <CardContent>
              <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup
                  onChange={event => this.handleChange(event.target.value)}>
                  {data_type_issue.map((item, index) => {
                    return (
                      <FormControlLabel
                        value={item}
                        control={<Radio />}
                        label={item}
                        key={index}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </CardContent>

            <CardActions>
              {this.state.type_issue != '' ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => this.clickNext()}>
                  Tiếp tục
                </Button>
              ) : null}
            </CardActions>
          </form>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(AccountDetails);
