import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import UsersTable from './Table';
import CONST_DATA from 'constant';
import Axios from 'axios';

const styles = theme => ({
  root: { padding: theme.spacing(3) }
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: null,
      user_id: null,
      data_success: false,
      data: null
    };
  }

  check_login = () => {
    let login_data = localStorage.getItem(CONST_DATA.localStorage_login);

    if (login_data == null) {
      this.props.history.push(CONST_DATA.router_sign_in);
    } else {
      login_data = JSON.parse(login_data);
      this.setState({
        access_token: login_data.access_token,
        user_id: login_data.user_profile.user_id
      });
    }
  };
  async componentDidMount() {
    await this.check_login();
    this.get_data();
  }

  get_data = () => {
    const body_req = {
      access_token: this.state.access_token
    };
    Axios.post(CONST_DATA.get_ticket_user + this.state.user_id, body_req)
      .then(response => {
        // console.log(response.data);
        if (response.data.status === 'success') {
          this.setState({
            data_success: true,
            data: response.data.data
          });
        } else {
          this.props.history.push(CONST_DATA.router_sign_in);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  go_detail = id => {
    this.props.history.push(CONST_DATA.router_yeucau + '/' + id);
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.state.data_success ? (
          <UsersTable
            data={this.state.data}
            go_detail={id => this.go_detail(id)}
          />
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(User);
