const data_type_issue = [
  'Sửa đổi, CHỈNH SỬA thông tin cá nhân',
  'Tôi gặp vấn đề khi CHUYỂN TIỀN và cần đối soát thủ công',
  'Tôi muốn đổi thông tin tài khoản ngân hàng',
  'Tôi cần SÁP NHẬP 2 tài khoản',
  'Tôi cần ĐỐI SOÁT rút tiền',
  'Tôi cần kiểm tra đơn hàng được hoàn tiền mục Túi Ba Gang',
  'Tôi bị sai số CMTND/CCCD, hộ chiếu'
];

const data_luu_y = [
  '1. Nếu bạn CHƯA đặt lệnh Rút tiền và muốn đổi thông tin tài khoản Rút, bạn chọn "Đổi thông tin"',
  '2. Nếu bạn chưa cập nhật được thông tin Tài khoản Rút, bạn vui lòng kiểm tra lại Số Tài Khoản, Tên ngân hàng (Hệ thống sẽ tự động điền Tên Chủ Khoản nếu người dùng đã nhập đúng 2 thông tin trên. Nếu nhập sai, sẽ không cập nhật được thông tin.',
  '3. Do các đơn yêu cầu cần xử lý thủ công nên sẽ mất khoảng 5-7 ngày làm việc không tính thứ 7 chủ nhật và các ngày nghỉ lễ.'
];

const data_luu_y_sua_thong_tin = [
  'ĐỂ ĐẢM BẢO AN TOÀN CHO TÀI KHOẢN CỦA NGƯỜI DÙNG VÀ TRÁNH TRANH CHẤP ĐÁNG TIẾC CÓ THỂ XẢY RA, HIỆN TẠI, EMAIL VÀ SỐ ĐIỆN THOẠI KHÔNG ĐƯỢC PHÉP THAY ĐỔI. FINHAY SẼ XEM XÉT THAY ĐỔI CHO CÁC TRƯỜNG HỢP TỪ TRƯỚC NGÀY 25 THÁNG 10 NĂM 2019. CÁC YÊU CẦU TỪ SAU KHOẢN THỜI GIAN NÀY SẼ KHÔNG ĐƯỢC XỬ LÝ',
  '-----------',
  'Vì lý do an ninh, tránh tình trạng tranh chấp tài khoản, việc thay đổi hoàn toàn tất cả các thông tin tài khoản là không thể. Trong những trường hợp đặc biệt, Finhay sẽ yêu cầu người dùng cung cấp và xác minh để đảm bảo thông tin là chính xác và là của chính người dùng, nhằm đề phòng các tranh chấp về sau'
];

const data_luu_y_chuyen_tien = [
  'Chuyển tiền thất bại thường do những lý do sau:',
  '-  Nhập sai nội dung chuyển tiền',
  '-  Nhập trùng nội dung cũ đã dùng trước đó',
  '-  Không ghi nội dung chuyển tiền',
  '-  Chuyển tiền qua cây ATM',
  'Finhay vẫn nhận được tiền của bạn nhưng lệnh nạp sẽ không thể cập nhật tự động mà cần đối soát thủ công, vì vậy bạn sẽ không thấy số dư được cập nhật ngay trên app.',
  'Trong trường hợp này, bạn vui lòng cung cấp biên lai chuyển tiền theo mẫu qui định tại đây để Finhay có cơ sở để kiểm tra và xử lý.'
];

const data_luu_y_rut_tien = [
  '  Rút tiền thất bại thường phát sinh do người dùng nhập sai thông tin tài khoản nhận, khi đó ngân hàng sẽ hoàn trả lại tiền vào tài khoản Finhay.',
  'Để thay đổi thông tin cá nhân, bạn cần nhập chính xác',
  '- Thông tin tài khoản ngân hàng chính xác của bạn bao gồm',
  '    + Tên chủ tài khoản ( Yêu cầu đúng theo quy định của ngân hàng, đầy đủ dấu cách, không có dấu ... )',
  '    + Tên ngân hàng',
  '    + Số tài khoản ( Lưu ý số tài khoản KHÔNG PHẢI số thẻ. Finhay không phải ví điện tử, không thể nhập số thẻ)'
];

const data_luu_y_bao_hiem = [
  '- Nếu bạn chuẩn bị tham gia bảo hiểm qua Finhay, nhưng thông tin cá nhân trên các màn hình đăng ký hiển thị sai, bạn hãy yêu cầu chỉnh sửa thông tin tại đây. Vì các đối tác cung cấp bảo hiểm có quy định chặt chẽ trong việc đảm bảo thông tin mà bạn đăng ký là chính xác, nên việc chỉnh sửa thông tin sẽ phải thực hiện qua Finhay để hạn chế sai sót xảy ra.',
  '- Hiện tại, bạn chỉ có thể mua bảo hiểm cho bản thân. Tính năng mua bảo hiểm cho người thân chưa được ra mắt.',
  '- Nếu bạn đã tham gia bảo hiểm tại Finhay, nhưng thông tin cá nhân trên Giấy chứng nhận bảo hiểm (GCNBH) không chính xác và bạn muốn sửa, hãy liên hệ với đội ngũ chăm sóc khách hàng của Finhay để được hướng dẫn. Finhay không hỗ trợ sửa thông tin trên GCNBH tại đây.'
];

const data_luu_y_hoan_tien = [
  '1. Finhay phải là liên kết cuối cùng bạn đã nhấn vào để mua sắm. Nếu bạn đi từ Finhay nhưng vẫn tiếp tục nhấn vào các liên kết khác (quảng cáo, trang giảm giá khác,...) thì đơn hàng của bạn sẽ không được ghi nhận trên hệ thống và không được hoàn tiền.',
  '2. Lưu ý khi mua hàng trên các trang thương mại điện tử (TMĐT)',
  '- Shopee thường có thời gian đối soát và hoàn tiền lâu nhất trong các sàn TMĐT. Tổng thời gian từ lúc bạn mua hàng tới lúc Shopee hoàn tiền thường lên tới 90 ngày. Shopee cũng giới hạn số tiền hoàn trên 1 sản phẩm là 0.35$ - khoảng 8.000đ.',
  '- Đối với Sendo: Tỷ lệ hoàn tiền khi bạn đặt hàng trên app (ứng dụng) Sendo cao hơn khi bạn đặt hàng trên website Sendo. Lý do: Sendo khuyến khích khách hàng trải nghiệm mua sắm trên app. Sendo cũng giới hạn tỷ lệ hoàn tiền trên mỗi sản phẩm, tùy thuộc vào từng ngành hàng.'
];

const data_type_sua_thong_tin = [
  {
    data: 'Tên',
    type: 'text'
  },
  {
    data: 'Ngày sinh',
    type: 'date'
  },
  {
    data: 'Địa chỉ',
    type: 'text'
  },
  {
    data: 'Giới tính',
    type: 'text'
  }
  // {
  //   data: 'Số CMTND/ CCCD/ Hộ chiếu',
  //   type: 'number'
  // },
  // {
  //   data: 'Ngày cấp CMTND/ CCCD/ Hộ chiếu',
  //   type: 'date'
  // },
  // {
  //   data: 'Nơi cấp CMTND/ CCCD/ Hộ chiếu',
  //   type: 'text'
  // }
];

const data_type_sua_thong_tin_bao_hiem = [
  'Họ và Tên',
  'Ngày tháng năm sinh',
  'Địa chỉ liên hệ',
  'Giới tính',
  'Loại giấy tờ',
  'Số CMTND/ CCCD/ Hộ chiếu',
  'Ngày cấp CMTND/ CCCD/ Hộ chiếu',
  'Nơi cấp CMTND/ CCCD/ Hộ chiếu'
];

const bien_lai_chuyen_tien = [
  'Lưu ý: Biên lai của bạn phải là biên lai đầy đủ và chính xác, bao gồm: ',
  '  - Nội dung chuyển tiền',
  '  - Ngày, giờ thực hiện chuyển tiền.',
  '  - Số tiền chuyển',
  '  - Tài khoản thụ hưởng',
  'Bạn có thể tìm thấy thông tin này trong mục lịch sử giao dịch của ứng dụng ngân hàng bạn đang sử dụng.'
];

const list_bank = [
  {
    id: 51,
    shortname: 'ABBANK',
    name: 'NH AN BINH',
    bank_code: '970425'
  },
  {
    id: 52,
    shortname: 'ACB',
    name: 'NH A CHAU',
    bank_code: '970416'
  },
  {
    id: 53,
    shortname: 'ANZ',
    name: 'NH ANZ VIET NAM',
    bank_code: null
  },
  {
    id: 54,
    shortname: 'AGRIBANK',
    name: 'NH NONG NGHIEP VA PHAT TRIEN NONG THON VIET NAM',
    bank_code: '970405'
  },
  {
    id: 55,
    shortname: 'BAC A BANK',
    name: 'NH BAC A',
    bank_code: '970409'
  },
  {
    id: 56,
    shortname: 'BAOVIET BANK',
    name: 'NH BAO VIET',
    bank_code: '970438'
  },
  {
    id: 57,
    shortname: 'BIDV',
    name: 'NH DAU TU VA PHAT TRIEN VIETNAM',
    bank_code: '970418'
  },
  {
    id: 58,
    shortname: 'CBBANK',
    name: 'NH XAY DUNG',
    bank_code: null
  },
  {
    id: 59,
    shortname: 'CIMB',
    name: 'NH CIMB VIET NAM',
    bank_code: '422589'
  },
  {
    id: 60,
    shortname: 'CO-OPBANK',
    name: 'NH HOP TAC XA VIET NAM',
    bank_code: null
  },
  {
    id: 61,
    shortname: 'CITIBANK',
    name: 'NH CITIBANK VIET NAM',
    bank_code: null
  },
  {
    id: 62,
    shortname: 'DONG A BANK',
    name: 'NH DONG A',
    bank_code: '970406'
  },
  {
    id: 63,
    shortname: 'EXIMBANK',
    name: 'NH XUAT NHAP KHAU',
    bank_code: '970431'
  },
  {
    id: 64,
    shortname: 'GPBANK',
    name: 'NH DAU KHI TOAN CAU',
    bank_code: '970408'
  },
  {
    id: 65,
    shortname: 'HDBANK',
    name: 'NH PHAT TRIEN TP HCM',
    bank_code: '970437'
  },
  {
    id: 66,
    shortname: 'HONGLEONGBANK',
    name: 'NH HONG LEONG VIETNAM',
    bank_code: '970442'
  },
  {
    id: 67,
    shortname: 'HSBC',
    name: 'NH HSBC VIET NAM',
    bank_code: null
  },
  {
    id: 68,
    shortname: 'INDOVINABANK',
    name: 'NH INDOVINA',
    bank_code: '970434'
  },
  {
    id: 69,
    shortname: 'KIEN LONG BANK',
    name: 'NH KIEN LONG',
    bank_code: '970452'
  },
  {
    id: 70,
    shortname: 'LIENVIET POST BANK',
    name: 'NH BUU DIEN LIEN VIET',
    bank_code: '970449'
  },
  {
    id: 71,
    shortname: 'MARITIME BANK',
    name: 'NH HANG HAI',
    bank_code: '970426'
  },
  {
    id: 72,
    shortname: 'MBBANK',
    name: 'NH QUAN DOI',
    bank_code: null
  },
  {
    id: 73,
    shortname: 'NAM A BANK',
    name: 'NH NAM A',
    bank_code: '970428'
  },
  {
    id: 74,
    shortname: 'NCB',
    name: 'NH QUOC DAN',
    bank_code: '970419'
  },
  {
    id: 75,
    shortname: 'OCB',
    name: 'NH PHUONG DONG',
    bank_code: '970448'
  },
  {
    id: 76,
    shortname: 'OCEANBANK',
    name: 'NH DAI DUONG',
    bank_code: '970414'
  },
  {
    id: 77,
    shortname: 'PGBANK',
    name: 'NH XANG DAU PETROLIMEX',
    bank_code: '970430'
  },
  {
    id: 78,
    shortname: 'PUBLICBANK',
    name: 'NH PUBLIC BANK VIET NAM',
    bank_code: '970439'
  },
  {
    id: 79,
    shortname: 'PVCOMBANK',
    name: 'NH DAI CHUNG',
    bank_code: '970412'
  },
  {
    id: 80,
    shortname: 'SACOMBANK',
    name: 'NH SAI GON THUONG TIN',
    bank_code: '970403'
  },
  {
    id: 81,
    shortname: 'SCB',
    name: 'NH SAI GON',
    bank_code: '970429'
  },
  {
    id: 82,
    shortname: 'SEABANK',
    name: 'NH DONG NAM A',
    bank_code: '970440'
  },
  {
    id: 83,
    shortname: 'SGB',
    name: 'NH SAI GON CONG THUONG',
    bank_code: '970400'
  },
  {
    id: 84,
    shortname: 'SHB',
    name: 'NH SAI GON HA NOI',
    bank_code: '970443'
  },
  {
    id: 85,
    shortname: 'SHINHANBANK',
    name: 'NH SHINHAN BANK VIET NAM',
    bank_code: '970424'
  },
  {
    id: 86,
    shortname: 'STANDARD CHARTERED',
    name: 'NH STANDARD CHARTERED VIET NAM',
    bank_code: null
  },
  {
    id: 87,
    shortname: 'TECHCOMBANK',
    name: 'NH KY THUONG',
    bank_code: '970407'
  },
  {
    id: 88,
    shortname: 'TPBANK',
    name: 'NH TIEN PHONG',
    bank_code: '970423'
  },
  {
    id: 89,
    shortname: 'UOB',
    name: 'NH UOB VIET NAM',
    bank_code: '970458'
  },
  {
    id: 90,
    shortname: 'VBSP',
    name: 'NH CHINH SACH XA HOI VIET NAM',
    bank_code: null
  },
  {
    id: 91,
    shortname: 'VDB',
    name: 'NH PHAT TRIEN VIET NAM',
    bank_code: null
  },
  {
    id: 92,
    shortname: 'VIB',
    name: 'NH QUOC TE',
    bank_code: '970441'
  },
  {
    id: 93,
    shortname: 'VIET A BANK',
    name: 'NH VIET A',
    bank_code: '970427'
  },
  {
    id: 94,
    shortname: 'VIET CAPITAL BANK',
    name: 'NH BAN VIET',
    bank_code: '970454'
  },
  {
    id: 95,
    shortname: 'VIETBANK',
    name: 'NH VIETNAM THUONG TIN',
    bank_code: '970433'
  },
  {
    id: 96,
    shortname: 'VIETCOMBANK',
    name: 'NH NGOAI THUONG VIETNAM',
    bank_code: '970436'
  },
  {
    id: 97,
    shortname: 'VIETINBANK',
    name: 'NH CONG THUONG VIET NAM',
    bank_code: '970415'
  },
  {
    id: 98,
    shortname: 'VPBANK',
    name: 'NH VIET NAM THINH VUONG',
    bank_code: '970432'
  },
  {
    id: 99,
    shortname: 'VRBANK',
    name: 'NH LIEN DOANH VIET NGA',
    bank_code: '970421'
  },
  {
    id: 100,
    shortname: 'WOORI',
    name: 'NH WOORI VIET NAM',
    bank_code: '970457'
  }
];

// const data_luu_y_sat_nhap = [
//   '  Có rất nhiều người dùng vô tình tạo thành 2 tài khoản. Finhay sẽ hỗ trợ gộp tài khoản, tuy nhiên bạn cần đảm bảo:',
//   '- Hai tài khoản cần phải cập nhật đầy đủ các thông tin cá nhân sau, và các thông tin ở cả hai tài khoản này PHẢI TRÙNG KHỚP để chắc chắn rằng cả hai tài khoản đều thuộc về sở hữu của cùng 1 người',
//   '      + Họ Tên',
//   '      + Chứng Minh Thư / Căn Cước Công Dân,',
//   '      + Địa chỉ',
//   '      + Mã số thuế (Nếu có)',
//   '- Trong hai tài khoản phải có ÍT NHẤT 1 tài khoản chưa từng đầu tư'
// ];

const data_luu_y_sat_nhap = [
    '  Có rất nhiều người dùng vô tình tạo thành 2 tài khoản. Finhay sẽ hỗ trợ gộp tài khoản, tuy nhiên bạn cần đảm bảo:',
    '- Có rất nhiều người dùng vô tình tạo thành 2 tài khoản. Finhay sẽ hỗ trợ gộp tài khoản, tuy nhiên bạn cần đảm bảo: Hai tài khoản cần phải cập nhật đầy đủ các thông tin cá nhân (Họ Tên, Chứng Minh Thư / Căn Cước Công Dân, Địa chỉ) và các thông tin ở cả hai tài khoản này PHẢI TRÙNG KHỚP để chắc chắn rằng cả hai tài khoản đều thuộc về sở hữu của cùng 1 người.',
    ' Đồng thời Finhay chưa thể hỗ trợ sáp nhập hai tài khoản của bạn khi thuộc một trong 2 trường hợp sau:',
    '+ Một trong hai tài khoản đã xác thực cả Email và SĐT.',
    '+ Cả hai tài khoản đã đều phát sinh giao dịch nạp tiền vào Finhay.'
  ];

export {
  bien_lai_chuyen_tien,
  data_type_sua_thong_tin,
  data_type_issue,
  data_luu_y,
  data_luu_y_sua_thong_tin,
  data_luu_y_chuyen_tien,
  data_luu_y_rut_tien,
  list_bank,
  data_luu_y_sat_nhap,
  data_luu_y_bao_hiem,
  data_type_sua_thong_tin_bao_hiem,
  data_luu_y_hoan_tien
};
