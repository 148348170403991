import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
  Card,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import {
  data_luu_y_bao_hiem,
  data_type_sua_thong_tin,
  data_type_sua_thong_tin_bao_hiem
} from './data';
import Axios from 'axios';
import CONST_DATA from 'constant';
import { date_format_input } from 'utility';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginTop: theme.spacing(2)
    // width: 200
  },
  media: {
    maxWidth: 400
  },
  formControl: {
    margin: theme.spacing(1),
    maxWidth: 400,
    minWidth: 250
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

const type_of_rut_tien = ['Rút tiết kiệm', 'Rút đầu tư'];

class BaoHiem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loai_thong_tin: null,
      thong_tin_hien_tai: null,
      thong_tin_moi: null,
      ly_do: null,
      login_data: null
    };
  }

  check_login = () => {
    const login_data = localStorage.getItem(CONST_DATA.localStorage_login);
    if (login_data == null) {
      this.props.history.push(CONST_DATA.router_sign_in);
    } else {
      this.setState({
        login_data: JSON.parse(login_data)
      });
    }
  };

  componentDidMount() {
    this.check_login();
  }

  send_data = () => {
    let ticket_new = {
      user_id: this.state.login_data.user_profile.user_id,
      ticket_type: 'BẢO HIỂM',
      ticket_detail: [
        { type: 'Loại thông tin', data: this.state.loai_thong_tin },
        { type: 'Thông tin hiện tại', data: this.state.thong_tin_hien_tai },
        { type: 'Thông tin muốn sửa', data: this.state.thong_tin_moi },
        { type: 'Lý do', data: this.state.ly_do }
      ]
    };

    this.props.send_data(ticket_new);
  };

  render_send = () => {
    if (
      this.state.loai_thong_tin != null &&
      this.state.thong_tin_hien_tai != null &&
      this.state.thong_tin_moi != null
    ) {
      return (
        <Button
          color="primary"
          variant="contained"
          onClick={() => this.send_data()}
          disabled={this.props.disabled_btn}
          >
          Xác nhận và gửi
        </Button>
      );
    } else {
      return null;
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.root}>
        <form autoComplete="off" noValidate>
          <CardContent>
            <Typography variant="h2" gutterBottom>
              Chỉnh sửa thông tin cá nhân mua bảo hiểm
            </Typography>
          </CardContent>
          <Divider />

          <CardContent>
            <Typography variant="h4" gutterBottom>
              Lưu ý:
            </Typography>
            {data_luu_y_bao_hiem.map(item => (
              <Typography>{item}</Typography>
            ))}
          </CardContent>

          <Divider></Divider>

          <CardContent>
            <Typography variant="h5">Loại thông tin bạn muốn sửa</Typography>
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                onChange={event =>
                  this.setState({ loai_thong_tin: event.target.value })
                }>
                {data_type_sua_thong_tin_bao_hiem.map(item => {
                  return (
                    <FormControlLabel
                      value={item}
                      control={<Radio />}
                      label={item}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </CardContent>
          <Divider></Divider>

          <CardContent>
            <Typography variant="h5">
              Thông tin sai (thông tin đang hiển thị trên app)
            </Typography>

            <TextField
              id="outlined-basic"
              className={classes.textField}
              variant="outlined"
              fullWidth
              type="text"
              value={this.state.thong_tin_hien_tai}
              onChange={event =>
                this.setState({ thong_tin_hien_tai: event.target.value })
              }
            />
          </CardContent>
          <Divider></Divider>

          <CardContent>
            <Typography variant="h5">Thông tin muốn sửa</Typography>
            <TextField
              multiline={true}
              id="outlined-basic"
              className={classes.textField}
              variant="outlined"
              fullWidth
              type="text"
              value={this.state.thong_tin_moi}
              onChange={event =>
                this.setState({ thong_tin_moi: event.target.value })
              }
            />
          </CardContent>
          <Divider></Divider>

          <CardContent>
            <Typography variant="h5">Chi tiết lý do</Typography>
            <TextField
              id="outlined-basic"
              className={classes.textField}
              variant="outlined"
              fullWidth
              type="text"
              value={this.state.ly_do}
              onChange={event => this.setState({ ly_do: event.target.value })}
              multiline={true}
            />
          </CardContent>
          <Divider></Divider>

          <CardActions>
            <Button
              color="black"
              variant="contained"
              onClick={() => this.props.clickBack()}>
              Quay lại
            </Button>
            {this.render_send()}
          </CardActions>
        </form>
      </Card>
    );
  }
}

export default withStyles(styles)(BaoHiem);
