import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { RouteWithLayout } from './components';
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  MinimalApp,
  MainApp
} from './layouts';

import {
  SignIn as SignInView,
  NotFound as NotFoundView,
  UserList,
  UserListApp,
  CreatIssue,
  CreatIssueApp,
  Issue,
  IssueApp
} from './views';
import CONST_DATA from 'constant';

const check_mobile = () => {
  if (window.innerWidth > window.innerHeight) {
    return MainLayout;
  } else {
    return MainApp;
  }
};

const check_mobile_mini = () => {
  if (window.innerWidth > window.innerHeight) {
    return MinimalLayout;
  } else {
    return MinimalApp;
  }
};

const Routes = () => {
  check_mobile();
  return (
    <Switch>
      <Redirect exact from="/" to={CONST_DATA.router_taoyeucau} />

      <RouteWithLayout
        component={UserList}
        exact
        layout={check_mobile()}
        path={CONST_DATA.router_list_yeu_cau}
      />

      <RouteWithLayout
        component={UserListApp}
        exact
        layout={check_mobile()}
        path={`${CONST_DATA.router_app_list_yeu_cau}/:user_id/:token`}
      />

      <RouteWithLayout
        component={CreatIssue}
        exact
        layout={check_mobile()}
        path={CONST_DATA.router_taoyeucau}
      />

      <RouteWithLayout
        component={CreatIssueApp}
        exact
        layout={check_mobile()}
        path={CONST_DATA.router_app_taoyeucau + '/:user_id' + '/:token'}
      />

      <RouteWithLayout
        component={Issue}
        exact
        layout={check_mobile()}
        path={CONST_DATA.router_yeucau + '/:id'}
      />

      <RouteWithLayout
        component={IssueApp}
        exact
        layout={check_mobile()}
        path={
          CONST_DATA.router_app_yeucau + '/:user_id' + '/:token' + '/:ticket_id'
        }
      />

      <RouteWithLayout
        component={Issue}
        exact
        layout={check_mobile()}
        path={CONST_DATA.router_yeucau + '/:id' + '/:token'}
      />

      <RouteWithLayout
        component={SignInView}
        exact
        layout={check_mobile_mini()}
        path={CONST_DATA.router_sign_in}
      />

      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path={CONST_DATA.router_not_found}
      />
      <Redirect to={CONST_DATA.router_not_found} />
    </Switch>
  );
};

export default Routes;
