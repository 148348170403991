const images = {
  mau_bien_lai: require('../src/assets/image/bienlai.png'),
  mau_tin_nhan: require('../src/assets/image/mautinnhan.jpg'),

  hoan_tien_1: require('../src/assets/image/hoan_tien_1.png'),
  hoan_tien_2: require('../src/assets/image/hoan_tien_2.png'),
  hoan_tien_3: require('../src/assets/image/hoan_tien_3.png'),
  hoan_tien_4: require('../src/assets/image/hoan_tien_4.png')
};

export default images;
