import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  TextField
} from '@material-ui/core';

import CONST_DATA from 'constant';
import Axios from 'axios';

const styles = theme => ({});

class Rating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 0,
      chosing: false,
      comment: '',
      label_text: 'Cảm nhận của bạn về dịch vụ của chúng tôi'
    };
  }

  send_rating = () => {
    const body_req = {
      ticket_id: this.props.ticket_id,
      rating: this.state.rating,
      rating_ly_do: this.state.comment
    };

    Axios.post(CONST_DATA.add_rating, body_req)
      .then(response => {
        window.location.reload(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  chose_star = number => {
    this.setState({
      rating: number,
      chosing: true,
      label_text:
        'Hãy chia sẻ cảm nhận của bạn và đóng góp cho hệ thống chăm sóc khách hàng của chúng tôi'
    });
  };

  show_star = number => {
    if (number <= this.state.rating) {
      return <p style={{ fontSize: 30 }}>⭐</p>;
    } else {
      return <p style={{ fontSize: 30 }}>☆</p>;
    }
  };

  render_send = () => {
    if (this.state.chosing == true) {
      if (this.state.rating <= 3) {
        if (this.state.comment.length > 5) {
          return (
            <Button
              style={{ margin: 10 }}
              color="primary"
              variant="contained"
              onClick={() => this.send_rating()}>
              Đánh giá
            </Button>
          );
        } else {
          return null;
        }
      } else {
        return (
          <Button
            style={{ margin: 10 }}
            color="primary"
            variant="contained"
            onClick={() => this.send_rating()}>
            Đánh giá
          </Button>
        );
      }
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Box style={{ margin: 10, marginTop: 30 }}>
        <Typography variant="h4">Đánh giá về trải nghiệm hỗ trợ</Typography>
        <Box
          display="flex"
          flexDirection="row"
          style={{ margin: 10, marginTop: 30 }}>
          <span onClick={() => this.chose_star(1)}>{this.show_star(1)}</span>
          <span onClick={() => this.chose_star(2)}>{this.show_star(2)}</span>
          <span onClick={() => this.chose_star(3)}>{this.show_star(3)}</span>
          <span onClick={() => this.chose_star(4)}>{this.show_star(4)}</span>
          <span onClick={() => this.chose_star(5)}>{this.show_star(5)}</span>
        </Box>

        {this.state.chosing ? (
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              style={{ margin: 10 }}
              maxWidth={400}
              placeholder={this.state.label_text}
              multiline={true}
              id="outlined-basic"
              variant="outlined"
              className={classes.textField}
              value={this.state.comment}
              onChange={event => this.setState({ comment: event.target.value })}
            />
            {this.render_send()}
          </Box>
        ) : null}
      </Box>
    );
  }
}

export default withStyles(styles)(Rating);
