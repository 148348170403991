import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Axios from 'axios';
import {
  Card,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
  Typography,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  CircularProgress
} from '@material-ui/core';
import {
  data_type_issue,
  data_luu_y_sua_thong_tin,
  data_type_sua_thong_tin
} from './data';
import CONST_DATA from 'constant';
import { date_format_input } from 'utility';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginTop: theme.spacing(2)
    // width: 200
  }
});

class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loai_tt_can_sua: '',
      Loai_tt_can_sua_type: 'text',
      ly_do: '',
      thong_tin_moi: '',
      thong_tin_hien_tai: '',
    };
  }

  check_login = () => {
    const login_data = localStorage.getItem(CONST_DATA.localStorage_login);
    if (login_data == null) {
      this.props.history.push(CONST_DATA.router_sign_in);
    } else {
      this.setState({
        thong_tin_hien_tai: JSON.parse(login_data)
      });
    }
  };

  componentDidMount() {
    this.check_login();
  }

  search = (nameKey, myArray) => {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].data === nameKey) {
        return myArray[i];
      }
    }
  };

  handleChange = text => {
    let obj_find = this.search(text, data_type_sua_thong_tin);
    this.check_tt_hien_tai(text);
    this.setState({
      Loai_tt_can_sua: text,
      Loai_tt_can_sua_type: obj_find.type
    });
  };

  send_data = () => {
    let ticket_new = {
      user_id: this.state.thong_tin_hien_tai.user_profile.user_id,
      ticket_type: 'SỬA THÔNG TIN',
      ticket_detail: [
        { type: 'Loại thay đổi', data: this.state.Loai_tt_can_sua },
        { type: 'Lý do', data: this.state.ly_do },
        { type: 'Thông tin mới', data: this.state.thong_tin_moi }
      ]
    };

    if (this.state.Loai_tt_can_sua_type == 'date') {
      ticket_new = {
        user_id: this.state.thong_tin_hien_tai.user_profile.user_id,
        ticket_type: 'SỬA THÔNG TIN',
        ticket_detail: [
          { type: 'Loại thay đổi', data: this.state.Loai_tt_can_sua },
          { type: 'Lý do', data: this.state.ly_do },
          {
            type: 'Thông tin mới',
            data: date_format_input(this.state.thong_tin_moi)
          }
        ]
      };
    }

    this.props.send_data(ticket_new);
  };

  render_send = () => {
    if (this.props.loading) {
      return <CircularProgress></CircularProgress>
    }
    if (this.state.ly_do != '' && this.state.thong_tin_moi != '') {
      return (
        <Button
          color="primary"
          variant="contained"
          onClick={() => this.send_data()}
          disabled={this.props.disabled_btn}
          >
          Xác nhận và gửi
        </Button>
      );
    } else {
      return null;
    }
  };

  check_tt_hien_tai = text => {
    let result = null;
    if (
      text == 'Email (chỉ hỗ trợ đổi nếu email hiện tại của bạn CHƯA XÁC THỰC)'
    ) {
      result = this.state.thong_tin_hien_tai.user_profile.email;
    }
    if (text == 'Tên') {
      result = this.state.thong_tin_hien_tai.user_profile.full_name;
    }
    if (text == 'Ngày sinh') {
      result = this.state.thong_tin_hien_tai.user_profile.birthday;
    }
    if (text == 'Địa chỉ') {
      result = this.state.thong_tin_hien_tai.user_profile.address;
    }
    if (text == 'Giới tính') {
      if (this.state.thong_tin_hien_tai.user_profile.gender == 1) {
        result = 'NAM';
      } else {
        result = 'NỮ';
      }
    }
    if (text == 'Số CMTND/ CCCD/ Hộ chiếu') {
      result = this.state.thong_tin_hien_tai.user_profile.identity_number;
    }
    if (text == 'Ngày cấp CMTND/ CCCD/ Hộ chiếu') {
      result = this.state.thong_tin_hien_tai.user_profile.id_issue_date;
    }
    if (text == 'Nơi câp CMTND/ CCCD/ Hộ chiế') {
      result = this.state.thong_tin_hien_tai.user_profile.id_issue_address;
    }

    this.setState({
      thong_tin_hien_tai_value: result
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.root}>
        <form autoComplete="off" noValidate>
          <CardContent>
            <Typography variant="h2" gutterBottom>
              Bạn đang cần hỗ trợ ...?
            </Typography>
          </CardContent>
          <Divider />
          <CardContent>
            <Typography variant="h4" gutterBottom>
              Lưu ý:
            </Typography>
            {data_luu_y_sua_thong_tin.map(item => (
              <Typography>{item}</Typography>
            ))}
          </CardContent>
          <Divider />
          <CardContent>
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                onChange={event => this.handleChange(event.target.value)}>
                {data_type_sua_thong_tin.map(item => {
                  return (
                    <FormControlLabel
                      value={item.data}
                      control={<Radio />}
                      label={item.data}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </CardContent>
          <Divider></Divider>
          {this.state.Loai_tt_can_sua == '' ? null : (
            <CardContent>
              <form className={classes.root} noValidate autoComplete="off">
                <Typography>Lý do thay đổi</Typography>
                <TextField
                  multiline={true}
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  className={classes.textField}
                  value={this.state.ly_do}
                  onChange={event =>
                    this.setState({ ly_do: event.target.value })
                  }
                />

                <Typography>Thông tin hiện tại</Typography>
                <TextField
                  multiline={true}
                  id="outlined-basic"
                  variant="outlined"
                  className={classes.textField}
                  fullWidth
                  disabled
                  value={this.state.thong_tin_hien_tai_value}
                />

                <Typography>Thông tin muốn sửa</Typography>
                <TextField
                  id="outlined-basic"
                  className={classes.textField}
                  variant="outlined"
                  multiline={true}
                  fullWidth
                  type={this.state.Loai_tt_can_sua_type}
                  value={this.state.thong_tin_moi}
                  onChange={event =>
                    this.setState({ thong_tin_moi: event.target.value })
                  }
                />
              </form>
            </CardContent>
          )}
          <CardActions> <Button
            color="black"
            variant="contained"
            onClick={() => this.props.clickBack()}>
            Quay lại
            </Button>
            {this.render_send()}
          </CardActions>
        </form>
      </Card>
    );
  }
}

export default withStyles(styles)(AccountDetails);
