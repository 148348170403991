import React, { Component } from 'react';

import CONST_DATA from 'constant';
import Axios from 'axios';

class Issue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: null,
      user_id: null,
      ticket_id: this.props.match.params.id
    };
  }

  check_login = () => {
    Axios.get(
      CONST_DATA.get_profile +
        '/' +
        this.props.match.params.user_id +
        '/' +
        this.props.match.params.token
    )
      .then(response => {
        // console.log(response.data);
        if (response.data.status == 'success') {
          this.setState({
            access_token: response.data.data.access_token,
            user_id: response.data.data.user_id
          });
          localStorage.setItem(
            CONST_DATA.localStorage_login,
            JSON.stringify(response.data.data)
          );

          this.props.history.push(
            CONST_DATA.router_yeucau + '/' + this.props.match.params.ticket_id
          );
        } else {
          this.props.history.push(CONST_DATA.router_sign_ins);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  async componentDidMount() {
    await this.check_login();
  }

  render() {
    return null;
  }
}

export default Issue;
