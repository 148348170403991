import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
  Card,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  CircularProgress
} from '@material-ui/core';
import { data_type_sua_thong_tin, data_luu_y_sat_nhap } from './data';
import Axios from 'axios';
import CONST_DATA from 'constant';
import { date_format_input } from 'utility';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginTop: theme.spacing(2)
    // width: 200
  },
  media: {
    maxWidth: 400
  },
  formControl: {
    margin: theme.spacing(1),
    maxWidth: 400,
    minWidth: 250
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

const type_of_rut_tien = ['Rút tiết kiệm', 'Rút đầu tư'];

class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ly_do: ''
    };
  }

  check_login = () => {
    const login_data = localStorage.getItem(CONST_DATA.localStorage_login);
    if (login_data == null) {
      this.props.history.push(CONST_DATA.router_sign_in);
    } else {
      this.setState({
        thong_tin_hien_tai: JSON.parse(login_data)
      });
    }
  };

  componentDidMount() {
    this.check_login();
  }

  send_data = () => {
    let ticket_new = {
      user_id: this.state.thong_tin_hien_tai.user_profile.user_id,
      ticket_type: 'SỬA CMTND CCCD',
      ticket_detail: [{ type: 'Chi tiết yêu cầu', data: this.state.ly_do }]
    };

    this.props.send_data(ticket_new);
  };

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.root}>
        <form autoComplete="off" noValidate>
          <CardContent>
            <Typography variant="h2" gutterBottom>
              Finhay cập nhật thông tin CMTND/CCCD hộ chiếu của tôi chưa đúng
            </Typography>
          </CardContent>
          <Divider />

          <CardContent>
            <Typography variant="h5" gutterBottom>
              Chi tiết yêu cầu
            </Typography>
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                multiline={true}
                type="text"
                className={classes.textField}
                value={this.state.ly_do}
                onChange={event => this.setState({ ly_do: event.target.value })}
              />
            </form>
          </CardContent>
          <Divider />

          <CardActions>
            <Button
              color="black"
              variant="contained"
              onClick={() => this.props.clickBack()}>
              Quay lại
            </Button>
            {this.props.loading ? <CircularProgress></CircularProgress> : <Button
              color="primary"
              variant="contained"
              onClick={() => this.send_data()}
              disabled={this.props.disabled_btn}
              >
              Xác nhận và gửi
            </Button>}

          </CardActions>
        </form>
      </Card>
    );
  }
}

export default withStyles(styles)(AccountDetails);
