import moment from 'moment';
import jwt from 'jsonwebtoken';

const to_date = date_num => {
  let time = moment(date_num * 1000)
    .locale('vi')
    .format('DD / MM / YYYY, h A');
  return time;
};

const to_date_min = date_num => {
  let time = moment(date_num * 1000)
    .locale('vi')
    .format('DD/MM - h:A');
  return time;
};

const get_time_second = () => {
  let time_now = new Date().getTime() / 1000;
  time_now = Math.round(time_now);
  return time_now;
};

const status_detail = id => {
  id = parseInt(id);
  let result = null;
  if (id == 1) {
    result = 'Tạo đơn thành công';
  } else if (id == 10) {
    result = 'Finhay đã nhận đơn';
  } else if (id == 100) {
    result = 'Finhay đang giải quyết';
  } else if (id == 200) {
    result = 'Đóng đơn yêu cầu';
  } else if (id == 300) {
    result = 'Chờ bổ sung thông tin';
  } else if (id === 350) {
    result = 'Điều chỉnh đơn thành công';
  } else if (id == 400) {
    result = 'Giải quyết thành công';
  }
  else if (id == 250) {
    result = 'Cập nhật tài khoản ngân hàng';
  }
  else if (id == 500) {
    result = 'Đã chuyển tiền';
  }
  return result;
};

const napas_link = (bank_code, stk) => {
  let api =
    'https://api-v2.finhay.com.vn/api/v2/payments/bank/check?bank_code=' +
    bank_code.toString() +
    '&account_number=' +
    stk.toString();

  return api;
};

const date_format_input = date_string => {
  date_string = date_string.split('-');
  let date_string_new =
    date_string[2] + '/' + date_string[1] + '/' + date_string[0];
  console.log(date_string_new);
  return date_string_new;
};

const key_token = 'finhaycs';

const decode_token = (data, callback) => {
  jwt.verify(data, key_token, { ignoreExpiration: false }, (err, decoded) =>
    callback(err, decoded)
  );
};




export {
  decode_token,
  date_format_input,
  to_date,
  to_date_min,
  status_detail,
  get_time_second,
  napas_link
};
