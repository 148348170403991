import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
  Card,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  CardMedia,
  Box,
  CircularProgress
} from '@material-ui/core';
import { data_luu_y_hoan_tien } from './data';
import Axios from 'axios';
import CONST_DATA from 'constant';
import { date_format_input } from 'utility';
import images from '../../images';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1)
    // width: 200
  },
  media: {
    maxWidth: 400
  },
  formControl: {
    margin: theme.spacing(1),
    maxWidth: 400,
    minWidth: 250
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },

  huongDan: {
    marginTop: theme.spacing(2)
  }
});

const type_of_van_de = [
  'Chưa nhận được tiền',
  'Nhận được ít hơn số tiền được ghi nhận'
];

class HoanTien extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ly_do: '',
      type_of_van_de: null,
      file_data: [],
      error: null,
      file_count: 0
    };
  }
  check_login = () => {
    const login_data = localStorage.getItem(CONST_DATA.localStorage_login);
    if (login_data == null) {
      this.props.history.push(CONST_DATA.router_sign_in);
    } else {
      this.setState({
        thong_tin_hien_tai: JSON.parse(login_data)
      });
    }
  };

  componentDidMount() {
    this.check_login();
  }

  send_data = () => {
    let ticket_new = {
      user_id: this.state.thong_tin_hien_tai.user_profile.user_id,
      ticket_type: 'HOÀN TIỀN',
      ticket_detail: [
        { type: 'Vấn đề gặp phải', data: this.state.type_of_van_de },
        { type: 'Chi tiết yêu cầu', data: this.state.ly_do },
        ...this.state.file_data
      ]
    };
    this.props.send_data(ticket_new);

    // console.log(ticket_new);
  };

  delete_img = i => {
    let array_state = this.state.file_data;
    array_state.splice(i, 1);
    this.setState({
      file_data: array_state,
      file_count: this.state.file_count - 1
    });
  };

  handleChange = e => {
    e.preventDefault();
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.upload_image(file, reader.result);
    };
  };

  upload_image = (file, base64) => {
    if (file.size >= 2000000) {
      this.setState({
        error: 'File của bạn quá lớn (tối đa 2MB)'
      });
    } else if (this.state.file_count == 2) {
      this.setState({
        error: 'Số lượng file tối đa là 1'
      });
    } else {
      this.setState({
        error: null,
        file_count: this.state.file_count + 1,
        file_data: [
          ...this.state.file_data,
          {
            data: base64,
            type: file.name
          }
        ]
      });
    }
  };

  render_send = () => {
    if (this.props.loading) {
      return <CircularProgress></CircularProgress>
    }
    if (this.state.type_of_van_de != null && this.state.file_count > 0) {
      return (
        <Button
          color="primary"
          variant="contained"
          onClick={() => this.send_data()}
          disabled={this.props.disabled_btn}
          >
          Xác nhận và gửi
        </Button>
      );
    } else {
      return null;
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.root}>
        <form autoComplete="off" noValidate>
          <CardContent>
            <Typography variant="h2" gutterBottom>
              Đối soát Hoàn tiền
            </Typography>
          </CardContent>

          <Divider />

          <CardContent>
            <Typography variant="h4" gutterBottom>
              Lưu ý:
            </Typography>
            {data_luu_y_hoan_tien.map(item => (
              <Typography>{item}</Typography>
            ))}
          </CardContent>

          <Divider></Divider>

          <CardContent>
            <Typography variant="h5" gutterBottom>
              Vấn đề gặp phải
            </Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.type_of_van_de}
                onChange={event =>
                  this.setState({ type_of_van_de: event.target.value })
                }>
                {type_of_van_de.map(item => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardContent>
          <Divider />

          <CardContent>
            <Typography variant="h5" gutterBottom>
              Chi tiết yêu cầu
            </Typography>
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                id="outlined-basic"
                multiline={true}
                variant="outlined"
                type="text"
                className={classes.textField}
                value={this.state.ly_do}
                onChange={event => this.setState({ ly_do: event.target.value })}
              />
            </form>
          </CardContent>
          <Divider />

          <CardContent>
            <Typography variant="h4" gutterBottom>
              Các bước chụp ảnh màn hình biên lai hoàn tiền
            </Typography>
            <Typography className={classes.huongDan} variant="h4" gutterBottom>
              Bước 1: Trong app Finhay, nhấn chọn mục “Hoàn tiền” (hoặc “Túi Ba
              Gang”)
            </Typography>
            <CardMedia
              className={classes.media}
              component="img"
              alt="Contemplative Reptile"
              height="100%"
              objectFit="cover"
              image={images.hoan_tien_1}
            />
            <Typography className={classes.huongDan} variant="h4" gutterBottom>
              Bước 2: Nhấn vào biểu tượng túi túi tiền màu xanh, tại góc trên
              bên phải
            </Typography>

            <CardMedia
              className={classes.media}
              component="img"
              alt="Contemplative Reptile"
              height="100%"
              objectFit="cover"
              image={images.hoan_tien_2}
            />
            <Typography className={classes.huongDan} variant="h4" gutterBottom>
              Bước 3: Tại danh sách lịch sử đơn hàng, nhấn chọn đơn hàng mà bạn
              cần Finhay kiểm tra
            </Typography>

            <CardMedia
              className={classes.media}
              component="img"
              alt="Contemplative Reptile"
              height="100%"
              objectFit="cover"
              image={images.hoan_tien_3}
            />

            <Typography className={classes.huongDan} variant="h4" gutterBottom>
              Bước 4: Bạn chụp lại màn hình chi tiết đơn hàng
            </Typography>
            <CardMedia
              className={classes.media}
              component="img"
              alt="Contemplative Reptile"
              height="100%"
              objectFit="cover"
              image={images.hoan_tien_4}
            />
          </CardContent>
          <Divider />
          <CardContent>
            <Typography className={classes.huongDan} variant="h4" gutterBottom>
              Bước 5: Tải lên đây ảnh màn hình mà bạn vừa chụp
            </Typography>

            {this.state.file_data.map((item, index) => (
              <Box>
                <CardMedia
                  className={classes.media}
                  component="img"
                  alt="Contemplative Reptile"
                  width={window.innerWidth - 20}
                  objectFit="cover"
                  image={item.data}
                />
                <Typography variant="h5" gutterBottom>
                  Tên file: {item.type}
                </Typography>
                <Button
                  variant="contained"
                  color="red"
                  style={{ margin: 20 }}
                  onClick={() => this.delete_img(index)}>
                  Xóa
                </Button>
              </Box>
            ))}

            <Button
              variant="contained"
              component="label"
              style={{ margin: 20 }}>
              Upload File
              <input
                type="file"
                style={{ display: 'none' }}
                onChange={e => this.handleChange(e)}
              />
            </Button>
          </CardContent>

          <CardActions>
            <Button
              color="black"
              variant="contained"
              onClick={() => this.props.clickBack()}>
              Quay lại
            </Button>
            {this.render_send()}
          </CardActions>
        </form>
      </Card>
    );
  }
}

export default withStyles(styles)(HoanTien);
