import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  CardActions,
  Divider,
  Button,
  Typography,
  CardMedia,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  CircularProgress
} from '@material-ui/core';
import { data_luu_y_chuyen_tien, bien_lai_chuyen_tien } from './data';
import Axios from 'axios';
import images from '../../images';
import CONST_DATA from 'constant';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginTop: theme.spacing(2)
    // width: 200
  },
  media: {
    maxWidth: 400
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

// const type_of_chuyen_tien = ['Đầu tư', 'Tiết kiệm', 'Bảo hiểm', 'Cửu long', 'CD 3 tháng'];
const type_of_chuyen_tien = ['Tài khoản tiền'];

class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file_data: [],
      error: null,
      file_count: 0,
      type_of_chuyen_tien: null,
      goal_id: null,
      goal_list: [],
      so_tien: ''
    };
  }

  check_login = () => {
    const login_data = localStorage.getItem(CONST_DATA.localStorage_login);

    if (login_data == null) {
      this.props.history.push(CONST_DATA.router_sign_in);
    } else {
      this.setState({
        thong_tin_hien_tai: JSON.parse(login_data)
      });
    }
  };

  async componentDidMount() {
    await this.check_login();
    await this.find_goal_id(this.state.thong_tin_hien_tai.user_profile.user_id);
  }

  find_goal_id = user_id => {
    Axios.get(CONST_DATA.get_goal_id + user_id)
      .then(response => {
        console.log('goal', response.data);
        if (response.data.message == 'Success') {
          this.setState({
            goal_list: response.data.result
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleChange = e => {
    e.preventDefault();
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.upload_image(file, reader.result);
    };
  };

  upload_image = (file, base64) => {
    if (file.size >= 2000000) {
      this.setState({
        error: 'File của bạn quá lớn (tối đa 2MB)'
      });
    } else if (this.state.file_count == 4) {
      this.setState({
        error: 'Số lượng file tối đa là 4'
      });
    } else {
      this.setState({
        error: null,
        file_count: this.state.file_count + 1,
        file_data: [
          ...this.state.file_data,
          {
            data: base64,
            type: file.name
          }
        ]
      });
    }
  };

  send_data = () => {
    let ticket_new = {
      user_id: this.state.thong_tin_hien_tai.user_profile.user_id,
      ticket_type: 'CHUYỂN TIỀN',
      ticket_detail: [
        { type: 'Loại chuyển tiền', data: this.state.type_of_chuyen_tien },
        ...this.state.file_data
      ]
    };

    if (this.state.goal_id != null) {
      ticket_new = {
        user_id: this.state.thong_tin_hien_tai.user_profile.user_id,
        ticket_type: 'CHUYỂN TIỀN',
        ticket_detail: [
          { type: 'Loại chuyển tiền', data: this.state.type_of_chuyen_tien },
          { type: 'Hũ mục tiêu', data: this.state.goal_id.title },
          { type: 'goal_id', data: this.state.goal_id.id },
          // { type: 'Số tiền', data: this.state.so_tien },

          ...this.state.file_data
        ]
      };
    }
    console.log({ ticket_new })
    this.props.send_data(ticket_new);
  };

  render_send = () => {
    if (this.props.loading) {
      return <CircularProgress></CircularProgress>
    }
    if (this.state.file_count == 0 || this.state.type_of_chuyen_tien == null) {
      return null;
    }
    if (
      this.state.type_of_chuyen_tien == 'Đầu tư' &&
      this.state.goal_id == null
    ) {
      return null;
    } else {
      return (
        <Button
          color="primary"
          variant="contained"
          onClick={() => this.send_data()}
          disabled={this.props.disabled_btn}>
          Xác nhận và gửi
        </Button>
      );
    }
  };

  delete_img = i => {
    let array_state = this.state.file_data;
    array_state.splice(i, 1);
    this.setState({
      file_data: array_state,
      file_count: this.state.file_count - 1
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.root}>
        <form autoComplete="off" noValidate>
          <CardContent>
            <Typography variant="h2" gutterBottom>
              Đối soát chuyển tiền
            </Typography>
          </CardContent>
          <Divider />
          <CardContent>
            <Typography variant="h4" gutterBottom>
              Lưu ý:
            </Typography>
            {data_luu_y_chuyen_tien.map(item => {
              console.log({ item })
              return (
                <Typography>{item}</Typography>
              )
            })}
          </CardContent>
          <Divider />
          <CardContent>
            <Typography variant="h4" gutterBottom>
              Biên lai chuyển tiền
            </Typography>
            {bien_lai_chuyen_tien.map(item => {
              return (
                <Typography>{item}</Typography>
              )
            })}
          </CardContent>
          <Divider />
          <CardContent>
            <Typography variant="h4" gutterBottom>
              Mẫu tin nhắn chuẩn
            </Typography>
            <CardMedia
              className={classes.media}
              component="img"
              alt="Contemplative Reptile"
              height="100%"
              objectFit="cover"
              image={images.mau_tin_nhan}></CardMedia>
          </CardContent>
          <Divider />
          <CardContent>
            <Typography variant="h4" gutterBottom>
              Mẫu biên lai chuẩn
            </Typography>
            <CardMedia
              className={classes.media}
              component="img"
              alt="Contemplative Reptile"
              height="100%"
              objectFit="cover"
              image={images.mau_bien_lai}></CardMedia>
          </CardContent>
          <Divider />

          {/* <CardContent>
            <Typography variant="h4" gutterBottom>
              Nhập số tiền
            </Typography>
            <TextField
              id="outlined-basic"
              type="number"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={this.state.so_tien}
              onChange={event => this.setState({ so_tien: event.target.value })}
            />
          </CardContent>
          <Divider /> */}
          <CardContent>
            <Typography variant="h4" gutterBottom>
              Loại yêu cầu chuyển tiền
            </Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">
                Lựa chọn loại chuyển tiền
              </InputLabel>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.type_of_chuyen_tien}
                onChange={event =>
                  this.setState({ type_of_chuyen_tien: event.target.value })
                }>
                {type_of_chuyen_tien.map(item => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardContent>
          <Divider />
          {this.state.type_of_chuyen_tien === 'Đầu tư' ? (
            <CardContent>
              <Typography variant="h4" gutterBottom>
                Chọn hũ đầu tư
              </Typography>

              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Lựa chọn hũ
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.goal_id}
                  onChange={event =>
                    this.setState({
                      goal_id: event.target.value
                    })
                  }>
                  {this.state.goal_list && this.state.goal_list.map(item => (
                    <MenuItem value={item}>{item.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CardContent>
          ) : null}

          <CardContent>
            <Typography variant="h4" gutterBottom color="red">
              {this.state.error}
            </Typography>

            <Typography variant="h4" gutterBottom>
              Danh sách file đã upload
            </Typography>

            {this.state.file_data.map((item, index) => (
              <Box>
                <img
                  src={item.data}
                  maxWidth="200px"
                  height="200px"
                  objectFit="cover"
                />
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography variant="h5" gutterBottom>
                    Tên file: {item.type}
                  </Typography>
                  <Button
                    variant="contained"
                    color="red"
                    style={{ margin: 20 }}
                    onClick={() => this.delete_img(index)}>
                    Xóa
                  </Button>
                </Box>
              </Box>
            ))}

            <Button
              variant="contained"
              component="label"
              style={{ margin: 20 }}>
              Upload File
              <input
                type="file"
                style={{ display: 'none' }}
                onChange={e => this.handleChange(e)}
              />
            </Button>
          </CardContent>
          <CardActions>
            <Button
              color="black"
              variant="contained"
              onClick={() => this.props.clickBack()}>
              Quay lại
            </Button>
            {this.render_send()}
          </CardActions>
        </form>
      </Card>
    );
  }
}

export default withStyles(styles)(AccountDetails);
