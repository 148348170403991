import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Card, Typography, Box, Button } from '@material-ui/core';
import CONST_DATA from 'constant';

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
    backgroundColor: 'white',
    maxWidth: '350px',
    boxShadow: '0px 0px 1px grey',
    borderRadius: '2px'
  },
  box: {
    paddingTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between'
  }
});

class Issue extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;

    return (
      <Box className={classes.box}>
        <Button className={classes.root} href={CONST_DATA.router_taoyeucau}>
          <Typography variant="h5">Tạo đơn</Typography>
        </Button>
        <Button className={classes.root} href={CONST_DATA.router_list_yeu_cau}>
          <Typography variant="h5">Danh sách đơn</Typography>
        </Button>
      </Box>
    );
  }
}

export default withStyles(styles)(Issue);
