import React, { Component } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  TextField,
  Button,
  Box,
  CircularProgress
} from '@material-ui/core';

import CONST_DATA from 'constant';
import Axios from 'axios';
import moment from 'moment';
import {
  to_date,
  status_detail,
  to_date_min,
  get_time_second,
  decode_token
} from 'utility';
import CardCS from './CardCS';
import RatingStar from './RatingStar';
const ImageBase64 = ({ data }) => <img style={{ maxWidth: "500px" }} src={`${data}`} />
const renderType = (base64Data) => base64Data.substring("data:image/".length, base64Data.indexOf(";base64"));
const styles = theme => ({
  root: {
    padding: theme.spacing(3)
    // backgroundColor: 'white'
  }
});

const STATUS_ID_GHI_NHAN = 300;
const STATUS_ID_THANH_CONG = 400;
const STATUS_ID_BO_QUA = 200;

class Issue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: null,

      ticket_id: this.props.match.params.id,

      data_success: false,
      data: null,

      comment: null,
      disabled_edit: true,

      file_data: [],
      error: null,
      file_count: 0,
      token_confirm: false,
      loading_comment: false
    };
  }

  checkBase64 = (data) => {
    try {
      return ['png', 'jpeg', 'jpg'].includes(renderType(data))
    } catch (error) {
      return false;
    }
  }


  check_token = () => {
    let token = this.props.match.params.token;

    // neu token cung null luon thi login
    if (token == null) {
      this.props.history.push(CONST_DATA.router_sign_in);
    }
    // neu token co thi decode token
    else {
      Axios.get(CONST_DATA.login_via_token + token)
        .then(response => {
          if (response.data.status == 'success') {
            let login_data = JSON.stringify(response.data.data);

            localStorage.setItem(CONST_DATA.localStorage_login, login_data);
            this.get_data(
              this.state.ticket_id,
              response.data.data.access_token
            );
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  check_login = async () => {
    let login_data = localStorage.getItem(CONST_DATA.localStorage_login);

    // neu localStorage bi null thi check token
    if (login_data == null) {
      await this.check_token();
    }
    // neu khong thi get data trong localstorge
    else {
      login_data = JSON.parse(login_data);
      this.setState({
        access_token: login_data.access_token,
        user_id: login_data.user_profile.user_id
      });

      this.get_data(this.state.ticket_id, login_data.access_token);
    }
  };

  get_data = (id, access_token) => {
    const body_req = {
      access_token: access_token
    };

    Axios.post(CONST_DATA.get_ticket_id + id, body_req)
      .then(response => {
        console.log(response.data);
        if (response.data.status == 'success') {
          console.log(response.data.data.rating);

          this.setState({
            data_success: true,
            data: response.data.data,
            ticket_detail: response.data.data.ticket_detail
          });

          if (response.data.data.send_rating != null) {
            this.setState({
              rating: response.data.data.rating
            });
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  async componentDidMount() {
    await this.check_login();
  }

  render_content = (classes, data) => {
    return (
      <Card>
        {this.state.data.status_id == STATUS_ID_THANH_CONG &&
          this.state.data.rating == null ? (
          <RatingStar ticket_id={this.state.data.ticket_id} />
        ) : null}

        <Divider />

        <CardContent>
          <Typography variant="h4" gutterBottom>
            Mã yêu cầu: {data.ticket_id}
          </Typography>
          <Typography variant="h4" gutterBottom>
            Loại yêu cầu: {data.ticket_type}
          </Typography>

          <Typography variant="h4" gutterBottom>
            Trạng thái hiện tại: {status_detail(data.status_id)}
          </Typography>
        </CardContent>
        <Divider />

        <CardContent>
          <Typography variant="h4" gutterBottom>
            Chi tiết yêu cầu
          </Typography>
          {data.ticket_detail.map((item, index) =>
            this.render_ticket_detail(classes, item, index)
          )}
        </CardContent>
        {this.render_sua_lai(classes)}
        <Divider />
        <CardContent>
          {data.comment_log.map(item => this.render_comment_detail(item))}
        </CardContent>
        <Divider />
        {data.status_id == 400 ? null : this.render_comment(classes)}
      </Card>
    );
  };

  handleChange = e => {
    // console.log(e);
    e.preventDefault();
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.upload_image(file, reader.result);
    };
  };

  upload_image = (file, base64) => {
    if (file.size >= 5000000) {
      this.setState({
        error: 'File của bạn quá lớn (tối đa 5MB)'
      });
    } else if (this.state.file_count == 2) {
      this.setState({
        error: 'Số lượng file tối đa là 2'
      });
    } else {
      this.setState({
        error: null,
        file_count: this.state.file_count + 1,
        file_data: [
          ...this.state.file_data,
          {
            data: base64,
            type: file.name
          }
        ]
      });
    }
  };

  delete_img = i => {
    let array_state = this.state.file_data;
    array_state.splice(i, 1);
    // console.log(array_state);
    this.setState({
      file_data: array_state,
      file_count: this.state.file_count - 1
    });
  };

  btn_upload = () => {
    // if (this.state.data.ticket_detail[1].type == 'image_upload') {
    return (
      <div>
        {this.state.file_data.map((item, index) => (
          <Box>
            <img
              src={item.data}
              maxWidth="200px"
              height="200px"
              objectFit="cover"
            />
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="h5" gutterBottom>
                Tên file: {item.type}
              </Typography>
              <Button
                variant="contained"
                color="red"
                style={{ margin: 20 }}
                onClick={() => this.delete_img(index)}>
                Xóa
              </Button>
            </Box>
          </Box>
        ))}

        <Button variant="contained" component="label" style={{ margin: 20 }}>
          Upload File
          <input
            type="file"
            style={{ display: 'none' }}
            onChange={e => this.handleChange(e)}
          />
        </Button>
      </div>
    );
    // }
  };

  send_edited = () => {
    let body_req = null;
    let ticket_detail = null;

    // neu nguoi dung khong tai len gi ca thi lay lai state.ticket_detail
    if (this.state.file_data.length == 0) {
      body_req = {
        ticket_id: this.state.data.ticket_id,
        ticket_detail: this.state.ticket_detail,
        access_token: this.state.access_token
      };
    }
    // neu nguoi dung co tai len file moi
    else {
      ticket_detail = this.state.file_data;
      body_req = {
        ticket_id: this.state.data.ticket_id,
        ticket_detail: [...this.state.ticket_detail, ...ticket_detail],
        access_token: this.state.access_token
      };
    }

    console.log(body_req);

    Axios.post(CONST_DATA.edit_ticket, body_req)
      .then(response => {
        // console.log(response.data);
        if (response.data.status == 'success') {
          window.location.reload();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  render_sua_lai = classes => {
    if (
      this.state.data.status_id == STATUS_ID_GHI_NHAN &&
      (this.state.data.ticket_type === 'CHUYỂN TIỀN' ||
        this.state.data.ticket_type === 'SỬA THÔNG TIN TÀI KHOẢN NGÂN HÀNG')
    ) {
      return (
        <CardContent>
          {this.btn_upload()}
          <Button
            variant="contained"
            component="label"
            style={{ margin: 20 }}
            color="primary"
            onClick={() => this.send_edited()}>
            Lưu lại và gửi
          </Button>
        </CardContent>
      );
    }
    return null;
  };

  render_comment = classes => {
    return (
      <Box display="flex" flexDirection="column">
        <TextField
          style={{ margin: 10 }}
          maxWidth={400}
          label="Tin nhắn của bạn"
          multiline={true}
          id="outlined-basic"
          variant="outlined"
          className={classes.textField}
          value={this.state.comment}
          onChange={event => this.setState({ comment: event.target.value })}
        />
        {this.state.loading_comment ? <CircularProgress /> : <Button
          style={{ margin: 10 }}
          color="primary"
          variant="contained"
          onClick={() => this.send_comment()}>
          Gửi
        </Button>}
      </Box>
    );
  };

  send_comment = () => {
    const body_req = {
      user_id: this.state.data.user_id,
      ticket_id: this.state.data.ticket_id,
      comment: this.state.comment,
      date: get_time_second(),
      access_token: this.state.access_token
    };
    this.setState({ loading_comment: true })

    Axios.post(CONST_DATA.add_comment, body_req)
      .then(response => {
        this.setState({ loading_comment: false })
        // console.log(response.data);
        if (response.data.status == 'success') {
          window.location.reload(false);
        }
      })
      .catch(error => {
        this.setState({ loading_comment: false })
        console.log(error);
      });
  };

  render_comment_detail = item => {
    let comment_from = '';
    if (item.user_id > 0) {
      comment_from = 'Tin nhắn từ bạn';
    } else {
      comment_from = 'Tin nhắn từ Finhay';
    }

    if (item.type == 'image') {
      let linkImage = ''
      let checkSrc = item.comment.search("finhay.s3-ap-southeast-1.amazonaws.com")
     if(checkSrc){
       linkImage = item.comment
     }
     else{
       linkImage =CONST_DATA.get_image + item.comment
     }
      return (
        <Box>
          <img
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'contain'
            }}
            // src={CONST_DATA.get_image + item.comment}></img>
            src={linkImage}></img>
        </Box>
      );
    } else {
      return (
        <CardCS
          top={comment_from}
          mid={item.comment}
          bottom={to_date_min(item.date)}
        />
      );
    }
  };

  edit_ticket_detail = (value, index) => {
    let all_data = this.state.ticket_detail;

    all_data[index] = {
      data: value,
      type: this.state.ticket_detail[index].type
    };

    this.setState({
      ticket_detail: all_data
    });
  };

  render_ticket_detail = (classes, item, index) => {
    if (item.type == 'image_upload') {
      return (
        <img
          style={{ maxWidth: 400 }}
          src={item.data}></img>
      );
    }
    if (item.type == 'goal_id') {
      return null;
    } else {
      return (
        <>
          {
            this.checkBase64(item.data) ? <ImageBase64 data={item.data} ></ImageBase64> : <>
              <Typography gutterBottom>
                {item.type}
                {' : '}
                {item.data}
              </Typography>
            </>
          }
        </>
      );
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.state.data_success ? (
          this.render_content(classes, this.state.data)
        ) : (
          <CircularProgress />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Issue);
