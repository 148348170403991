import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
import CONST_DATA from 'constant';
import Axios from 'axios';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
});

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data_success: false,
      thong_tin_hien_tai: null
    };
  }

  check_login = () => {
    let login_data = localStorage.getItem(CONST_DATA.localStorage_login);
    // console.log(login_data, login_data == null);

    if (login_data != null) {
      login_data = JSON.parse(login_data);

      this.setState({
        thong_tin_hien_tai: login_data,
        data_success: true
      });
    }
  };


  componentDidMount() {
    this.check_login();
  }

  render() {
    // console.log(this.state);

    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Avatar
          alt="Person"
          className={classes.avatar}
          component={RouterLink}
          src={
            this.state.thong_tin_hien_tai != null
              ? this.state.thong_tin_hien_tai.user_profile.avatar
              : ''
          }
        />
        <Typography className={classes.name} variant="h4">
          {this.state.thong_tin_hien_tai != null
            ? this.state.thong_tin_hien_tai.user_profile.full_name
            : ''}
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(Profile);
