import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
  Card,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Box,
  CardMedia,
  CircularProgress
} from '@material-ui/core';
import {
  data_type_sua_thong_tin,
  data_luu_y_rut_tien,
  list_bank
} from './data';
import Axios from 'axios';
import CONST_DATA from 'constant';
import { napas_link } from 'utility';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginTop: theme.spacing(2)
    // width: 200
  },
  media: {
    maxWidth: 400
  },
  formControl: {
    minWidth: 200,
    maxWidth: 400,
    width: '10%'
  }
});

class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ten_tai_khoan: '',
      ten_ngan_hang: '',
      real_ten_ngan_hang: '',
      chi_nhanh: '',
      list_chi_nhanh: [],
      so_tai_khoan: '',
      napas: true,
      bank_full: null,
      error_code: null,

      file_data: [],
      error: null,
      file_count: 0,
      list_dia_chi: [],
      dia_chi: '',
      loading: false
    };
  }

  search = (nameKey, myArray) => {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].data === nameKey) {
        return myArray[i];
      }
    }
  };

  handleChange = text => {
    let obj_find = this.search(text, data_type_sua_thong_tin);
    // console.log(obj_find);

    this.setState({
      Loai_tt_can_sua: text,
      Loai_tt_can_sua_type: obj_find.type
    });
  };

  check_login = () => {
    const login_data = localStorage.getItem(CONST_DATA.localStorage_login);
    if (login_data == null) {
      this.props.history.push(CONST_DATA.router_sign_in);
    } else {
      this.setState({
        thong_tin_hien_tai: JSON.parse(login_data)
      });
    }
  };

  componentDidMount() {
    this.check_login();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.real_ten_ngan_hang !== this.state.real_ten_ngan_hang) {
      Axios.get(`${CONST_DATA.get_location_bank}?bank_name=${this.state.real_ten_ngan_hang}`)
        .then(response => {
          const result = response.data;
          if (result.status === 'success') {
            const list_dia_chi = result.data.map(el => {
              return el.location;
            })
            this.setState({ list_dia_chi, dia_chi: '', chi_nhanh: '' })
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
    if ((prevState.dia_chi !== this.state.dia_chi)) {
      Axios.get(`${CONST_DATA.get_branches_bank}?bank_name=${this.state.real_ten_ngan_hang}&location=${this.state.dia_chi}`)
        .then(response => {
          const result = response.data;
          if (result.status === 'success' && Array.isArray(result.data)) {
            const list_chi_nhanh = result.data.map(el => {
              return el.branch_name;
            })
            this.setState({ list_chi_nhanh, chi_nhanh: '' })
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }


  gen_data = ten_tai_khoan => {
    let ticket_new = {
      user_id: this.state.thong_tin_hien_tai.user_profile.user_id,

      ticket_type: 'SỬA THÔNG TIN TÀI KHOẢN NGÂN HÀNG',
      ticket_detail: [
        { type: 'Tên ngân hàng', data: this.state.bank_full.shortname },
        { type: 'Chi nhánh', data: this.state.chi_nhanh },
        { type: 'Số tài khoản', data: this.state.so_tai_khoan },
        { type: 'Tên chủ tài khoản', data: ten_tai_khoan },
        { type: 'Địa chỉ', data: this.state.dia_chi },
        ...this.state.file_data
      ]
    };
    return ticket_new;
  };

  send_data = () => {
    this.setState({ loading: true })
    if (this.state.bank_code == null) {
      let ticket_new = this.gen_data(this.state.ten_tai_khoan);
      this.props.send_data(ticket_new);
      this.setState({ loading: false })
    } else {
      Axios.get(napas_link(this.state.bank_code, this.state.so_tai_khoan))
        .then(response => {
          this.setState({ loading: false })
          // console.log(response.data);
          if (response.data.error_code === 0) {
            this.setState({ ten_tai_khoan: response.data.result.AccName });

            let ticket_new = this.gen_data(response.data.result.AccName);
            this.props.send_data(ticket_new);
          } else {
            this.setState({
              error_code:
                'Bạn vui lòng kiểm tra lại số tài khoản và tên ngân hàng'
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  render_send = () => {
    if (this.props.loading) {
      return <CircularProgress></CircularProgress>
    }
    if (this.state.so_tai_khoan !== '' && this.state.ten_ngan_hang !== '') {
      return (
        <Button
          color="primary"
          variant="contained"
          onClick={() => this.send_data()}
          disabled={this.props.disabled_btn}
          >
          Xác nhận và gửi
        </Button>
      );
    } else {
      return null;
    }
  };

  check_napas = () => {
    Axios.get(napas_link(this.state.bank_code, this.state.so_tai_khoan))
      .then(response => {
        // console.log(response.data);
        if (response.data.error_code === 0) {
          this.setState({ ten_tai_khoan: response.data.result.AccName });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  render_kiemtra = () => {
    // console.log(this.state.bank_code);

    if (this.state.bank_code !== null) {
      return (
        <Button
          style={{ marginTop: "15px" }}
          color="primary"
          variant="contained"
          onClick={() => this.check_napas()}>
          Kiểm tra
        </Button>
      );
    }
  };

  delete_img = i => {
    let array_state = this.state.file_data;
    array_state.splice(i, 1);
    this.setState({
      file_data: array_state,
      file_count: this.state.file_count - 1
    });
  };

  handleChange = e => {
    e.preventDefault();
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.upload_image(file, reader.result);
    };
  };

  upload_image = (file, base64) => {
    if (file.size >= 2000000) {
      this.setState({
        error: 'File của bạn quá lớn (tối đa 2MB)'
      });
    } else if (this.state.file_count > 5) {
      this.setState({
        error: 'Số lượng file tối đa là 4'
      });
    } else {
      this.setState({
        error: null,
        file_count: this.state.file_count + 1,
        file_data: [
          ...this.state.file_data,
          {
            data: base64,
            type: file.name
          }
        ]
      });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.root}>
        <form>
          <CardContent>
            <Typography variant="h2" gutterBottom>
              Thay đổi thông tin ngân hàng
            </Typography>
          </CardContent>
          <Divider />
          <CardContent>
            <Typography variant="h4" gutterBottom>
              Lưu ý:
            </Typography>
            {data_luu_y_rut_tien.map(item => (
              <Typography>{item}</Typography>
            ))}
          </CardContent>
          <Divider />

          <CardContent>
            <Typography variant="h5" gutterBottom>
              Tên ngân hàng
            </Typography>
            <Typography>
              Cần là tài khoản ngân hàng Hoạt động được tại Việt Nam. Trường hợp
              người dùng sử dụng ngân hàng điện tử Timo để mục này là VPBANK
            </Typography>

            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                fullWidth
                value={this.state.ten_ngan_hang}
                onChange={event => {
                  const bank_full = list_bank.find(
                    item => item.id == event.target.value
                  );
                  this.setState({
                    napas: bank_full.bank_code != null,
                    ten_ngan_hang: bank_full.id,
                    bank_full: bank_full,
                    bank_code: bank_full.bank_code,
                    real_ten_ngan_hang: bank_full.shortname.concat(` - ${bank_full.name}`)
                  }, () => {
                    console.log(this.state.bank_code)
                  });
                }}>
                {list_bank.map(item => (
                  <MenuItem value={item.id}>
                    {item.shortname}
                    {' - '}
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardContent>
          <Divider />
          <Divider />
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Số tài khoản
            </Typography>
            <Typography>
              Lưu ý số tài khoản KHÔNG PHẢI là dãy số được in trên thẻ. Số tài
              khoản là dãy số được ngân hàng cung cấp khi bạn đăng ký mở tài
              khoản ngân hàng. Nếu bạn không nhớ hoặc không lưu trữ, bạn có thể
              tìm lại tại mục thông tin tài khoản trong ứng dụng internet
              banking của ngân hàng đang sử dụng hoặc ra trực tiếp ngân hàng để
              lấy lại
            </Typography>
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                multiline={true}
                type="number"
                className={classes.textField}
                value={this.state.so_tai_khoan}
                onChange={event =>
                  this.setState({ so_tai_khoan: event.target.value })
                }
              />
              {this.render_kiemtra()}
            </form>
          </CardContent>

          <Divider />
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Tên chủ tài khoản
            </Typography>
            <Typography>
              Yêu cầu đúng theo quy định của ngân hàng, đúng thứ tự họ - tên,
              đầy đủ dấu cách, không có dấu ...
            </Typography>
            <form className={classes.root} noValidate autoComplete="off">
              {this.state.napas ? (
                <TextField
                  multiline={true}
                  id="outlined-basic"
                  placeholder="TEN CHU TAI KHOAN"
                  variant="outlined"
                  fullWidth
                  disabled
                  className={classes.textField}
                  value={this.state.ten_tai_khoan}
                  onChange={event =>
                    this.setState({ ten_tai_khoan: event.target.value })
                  }
                />
              ) : (
                <TextField
                  multiline={true}
                  id="outlined-basic"
                  placeholder="TEN CHU TAI KHOAN"
                  variant="outlined"
                  fullWidth
                  className={classes.textField}
                  value={this.state.ten_tai_khoan}
                  onChange={event =>
                    this.setState({ ten_tai_khoan: event.target.value })
                  }
                />
              )}
            </form>
          </CardContent>
          <Divider />
          {(this.state.bank_code === '970405' || this.state.bank_code === '970400') && <>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Địa điểm
            </Typography>
              <form className={classes.root} noValidate autoComplete="off">
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  fullWidth
                  value={this.state.dia_chi}
                  onChange={event => {
                    this.setState({
                      dia_chi: event.target.value
                    });
                  }}>
                  {this.state.list_dia_chi.map(item => (
                    <MenuItem value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </form>
            </CardContent>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Chi nhánh
            </Typography>
              <Typography>
                Hai trường hợp đặc biệt là Ngân hàng Nông Nghiệp và Phát triển
                Nông thôn Việt Nam - AGRIBANK và Ngân hàng Sài gòn Công thương -
                SGB yêu cầu thông tin trụ sở chính xác để chuyển tiền. Hai trường
                hợp thiếu thông tin này không thể giải quyết được, người dùng của
                2 ngân hàng này vui lòng lưu ý điền đầy đủ phần này
            </Typography>
              <form className={classes.root} noValidate autoComplete="off">
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  fullWidth
                  value={this.state.chi_nhanh}
                  onChange={event => {
                    this.setState({
                      chi_nhanh: event.target.value,
                    });
                  }}>
                  {this.state.list_chi_nhanh.map(item => (
                    <MenuItem value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </form>
            </CardContent>
          </>}

          <CardContent>
            <Typography variant="h5" gutterBottom>
              Tải hình ảnh
            </Typography>
            <Typography>
              Tải hình ảnh chứng minh bạn đã đóng tài khoản cũ, không còn sử
              dụng
            </Typography>

            {this.state.file_data.map((item, index) => (
              <Box>
                <CardMedia
                  className={classes.media}
                  component="img"
                  alt="Contemplative Reptile"
                  width={window.innerWidth - 20}
                  objectFit="cover"
                  image={item.data}
                />
                <Typography variant="h5" gutterBottom>
                  Tên file: {item.type}
                </Typography>
                <Button
                  variant="contained"
                  color="red"
                  style={{ margin: 20 }}
                  onClick={() => this.delete_img(index)}>
                  Xóa
                </Button>
              </Box>
            ))}

            <Button
              variant="contained"
              component="label"
              style={{ margin: 20 }}>
              Upload File
              <input
                type="file"
                style={{ display: 'none' }}
                onChange={e => this.handleChange(e)}
              />
            </Button>
          </CardContent>
          <Divider />

          <Typography variant="h5" gutterBottom>
            {this.state.error_code}
          </Typography>

          <CardActions>
            {!this.state.loading && <Button
              color="black"
              variant="contained"
              onClick={() => this.props.clickBack()}>
              Quay lại
            </Button>}
            {this.render_send()}
          </CardActions>
        </form>
      </Card>
    );
  }
}

export default withStyles(styles)(AccountDetails);
