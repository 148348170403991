import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
  Card,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { data_type_sua_thong_tin, data_luu_y_sat_nhap } from './data';
import Axios from 'axios';
import CONST_DATA from 'constant';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginTop: theme.spacing(2)
    // width: 200
  },
  media: {
    maxWidth: 400
  },
  formControl: {
    minWidth: 200
  }
});

class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      so_dt: '',
      ly_do: ''
    };
  }
  check_login = () => {
    const login_data = localStorage.getItem(CONST_DATA.localStorage_login);
    if (login_data == null) {
      this.props.history.push(CONST_DATA.router_sign_in);
    } else {
      this.setState({
        thong_tin_hien_tai: JSON.parse(login_data)
      });
    }
  };

  componentDidMount() {
    this.check_login();
  }

  send_data = () => {
    let ticket_new = {
      user_id: this.state.thong_tin_hien_tai.user_profile.user_id,
      ticket_type: 'SÁP NHẬP',
      ticket_detail: [
        { type: 'Email cần sáp nhập', data: this.state.email },
        { type: 'Số điện thoại cần sáp nhập', data: this.state.so_dt },
        { type: 'Chi tiết yêu cầu', data: this.state.ly_do }
      ]
    };

    this.props.send_data(ticket_new);
  };

  render_send = () => {
    if (this.props.loading) {
      return <CircularProgress></CircularProgress>
    }
    if (this.state.email != '' || this.state.so_dt != '') {
      return (
        <Button
          color="primary"
          variant="contained"
          onClick={() => this.send_data()}
          disabled={this.props.disabled_btn}
          >
          Xác nhận và gửi
        </Button>
      );
    } else {
      return null;
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.root}>
        <form autoComplete="off" noValidate>
          <CardContent>
            <Typography variant="h2" gutterBottom>
              Sáp nhập tài khoản
            </Typography>
          </CardContent>
          <Divider />
          <CardContent>
            <Typography variant="h4" gutterBottom>
              Lưu ý:
            </Typography>
            {data_luu_y_sat_nhap.map(item => (
              <Typography>{item}</Typography>
            ))}
          </CardContent>
          <Divider />
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Email của tài khoản cần sáp nhập
            </Typography>
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                multiline={true}
                id="outlined-basic"
                variant="outlined"
                type="email"
                fullWidth
                className={classes.textField}
                value={this.state.email}
                onChange={event => this.setState({ email: event.target.value })}
              />
            </form>
          </CardContent>
          <Divider />
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Số điện thoại của tài khoản cần sáp nhập
            </Typography>
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                id="outlined-basic"
                multiline={true}
                variant="outlined"
                fullWidth
                type="number"
                className={classes.textField}
                value={this.state.so_dt}
                onChange={event => this.setState({ so_dt: event.target.value })}
              />
            </form>
          </CardContent>
          <Divider />
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Chi tiết yêu cầu
            </Typography>
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                multiline={true}
                id="outlined-basic"
                variant="outlined"
                fullWidth
                className={classes.textField}
                value={this.state.ly_do}
                onChange={event => this.setState({ ly_do: event.target.value })}
              />
            </form>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              color="black"
              variant="contained"
              onClick={() => this.props.clickBack()}>
              Quay lại
            </Button>
            {this.render_send()}
          </CardActions>
        </form>
      </Card>
    );
  }
}

export default withStyles(styles)(AccountDetails);
