import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography } from '@material-ui/core';

import { to_date, status_detail, to_date_min } from 'utility';

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    backgroundColor: 'white'
  }
});

class Issue extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;

    return (
      <Card className={classes.root} onClick={() => this.props.onClick()}>
        <Typography variant="h5" component="h2">
          {this.props.top}
        </Typography>
        <Typography variant="h5" component="h2">
          {this.props.top2}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {this.props.mid}
        </Typography>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom>
          {this.props.bottom}
        </Typography>
      </Card>
    );
  }
}

export default withStyles(styles)(Issue);
