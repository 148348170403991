// // const localhost = 'http://localhost:3008/api_finhaycs/';
// let localhost;
// const environment = process.env.NODE_ENV;
// switch (environment) {
//   case 'development':
//     localhost = 'http://192.168.1.35:3008/api_finhaycs/'
//     break;
//   case 'production':
//     localhost = 'https://cs.finhay.com.vn/api_finhaycs/'
//     break;
//   default:
//     localhost = 'http://192.168.1.35:3008/api_finhaycs/'
//     break;
// }
const localhost = process.env.REACT_APP_API;
const CONST_DATA = {
  add_ticket: localhost + 'add_ticket',
  get_ticket_id: localhost + 'get_ticket/', //+id
  get_ticket_user: localhost + 'get_ticket_user/',
  check_spam: localhost + 'check_spam/', // + user_id
  check_token: localhost + 'add_ticket/check_token',
  get_location_bank: localhost + 'add_ticket/get_locations',
  get_branches_bank: localhost + 'add_ticket/get_branches',
  get_goal_id: localhost + 'add_ticket/get_goal_id/',
  update_user_profile: localhost + 'update_user_profile',
  get_user_bank: localhost + 'add_ticket/get-user-bank',

  edit_ticket: localhost + 'edit_ticket',
  file_upload: localhost + 'file/upload',
  get_image: localhost + 'get_image/',

  get_profile: localhost + 'user_api/get_profile',

  end_point_login: localhost + 'login',
  login_via_token: localhost + 'login_via_token/',

  localStorage_login: 'localStorage_login',

  router_sign_in: '/sign-in',
  router_not_found: '/not-found',

  // cho web
  router_yeucau: '/yeucau',
  router_taoyeucau: '/taoyeucau',
  router_list_yeu_cau: '/list',
  // cho app
  router_app_yeucau: '/app/yeucau', // +user_id+token + ticket_id
  router_app_taoyeucau: '/app/taoyeucau', // + user_id +token
  router_app_list_yeu_cau: '/app/list', // + user_id + token

  add_comment: localhost + 'add_comment',
  add_rating: localhost + 'add_rating'
};

export default CONST_DATA;
