import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  TextField,
  Paper,
  Typography
} from '@material-ui/core';
import styles from './styles';
import Axios from 'axios';
import CONST_DATA from 'constant';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error_text: ''
    };
  }

  componentDidMount() {
    localStorage.removeItem(CONST_DATA.localStorage_login);
  }

  check_respone_login = data => {
    // console.log(data);

    localStorage.setItem(CONST_DATA.localStorage_login, JSON.stringify(data));
    this.props.history.push('/taoyeucau');
  };

  sign_in = () => {
    const body = {
      username: this.state.email,
      password: this.state.password
    };

    const header = {
      ContentType: 'application/json'
    };

    Axios.post(CONST_DATA.end_point_login, body)
      .then(response => {
        // console.log(response.data);

        if (response.data.status === 'success') {
          // console.log(response.data);
          this.check_respone_login(response.data.data);
        }
        if (response.data.status === 'error') {
          this.setState({ error_text: response.data.data });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid className={classes.grid} container>
          <Grid className={classes.content} item lg={12} xs={12}>
            <div className={classes.content}>
              <Paper elevation={3}>
                <div className={classes.contentBody}>
                  <div className={classes.form}>
                    <Typography className={classes.title} variant="h2">
                      Sign in
                    </Typography>

                    <TextField
                      multiline={true}
                      className={classes.textField}
                      fullWidth
                      label="Email / Số điện thoại"
                      name="email"
                      type="text"
                      variant="outlined"
                      value={this.state.email}
                      onChange={event =>
                        this.setState({ email: event.target.value })
                      }
                    />
                    <TextField
                      className={classes.textField}
                      fullWidth
                      label="Password"
                      name="password"
                      onChange={event =>
                        this.setState({ password: event.target.value })
                      }
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          this.sign_in();
                        }
                      }}
                      type="password"
                      value={this.state.password}
                      variant="outlined"
                    />

                    <Typography>{this.state.error_text}</Typography>
                    <Button
                      className={classes.signInButton}
                      style={{ backgroundColor: '#00B935', color: 'white' }}
                      fullWidth
                      size="large"
                      variant="contained"
                      onClick={() => this.sign_in()}>
                      Đăng nhập
                    </Button>
                  </div>
                </div>
              </Paper>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(SignIn);
