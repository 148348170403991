import React, { Component } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';

import { to_date, to_date_min, status_detail } from 'utility';
import CONST_DATA from 'constant';
import CardCS from './CardCS';

const columns = [
  { label: 'Mã yêu cầu', minWidth: 10 },
  { label: 'Ngày tạo', minWidth: 10 },
  { label: 'Vấn đề ', minWidth: 10 },
  { label: 'Trạng thái', minWidth: 10 }
];

const styles = theme => ({
  root: {},
  container: {
    maxHeight: '80vh'
  }
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = { page: 0 };
  }

  render_table = classes => {
    return (
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.data.map(row => {
                return (
                  <TableRow
                    onClick={() => this.props.go_detail(row.ticket_id)}
                    hover
                    tabIndex={-1}>
                    <TableCell><Button>{row.ticket_id}</Button></TableCell>
                    <TableCell>{to_date_min(row.created_at)}</TableCell>
                    <TableCell>{row.ticket_type}</TableCell>
                    <TableCell>{status_detail(row.status_id)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        {window.innerWidth > window.innerHeight
          ? this.render_table(classes)
          : this.props.data.map(row => {
            return (
              <Button
                onClick={() => this.props.go_detail(row.ticket_id)}
                top={row.ticket_id}
                top2={status_detail(row.status_id)}
                mid={row.ticket_type}
                bottom={to_date_min(row.created_at)}
              />
            );
          })}
      </div>
    );
  }
}

export default withStyles(styles)(User);
