import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify'
import { napas_link } from 'utility';
import {
  Card,
  CardContent,
  CardActions,
  Divider,
  Typography,
  FormControl,
  Select,
  Input,
  CardMedia,
  Box,
  MenuItem,
  InputLabel,
  CircularProgress
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { data_type_sua_thong_tin, data_luu_y_sat_nhap, data_luu_y_rut_tien, list_bank } from './data';
import Axios from 'axios';
import CONST_DATA from 'constant';
import { date_format_input } from 'utility';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginTop: theme.spacing(2)
    // width: 200
  },
  media: {
    maxWidth: 400
  },
  formControl: {
    margin: theme.spacing(1),
    maxWidth: 400,
    minWidth: 250
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

const type_of_rut_tien = ['Rút tiết kiệm', 'Rút đầu tư'];

class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ngay_rut: null,
      so_tien: '',
      ly_do: '',
      type_of_rut_tien: null,
      xac_nhan_thong_tin_ngan_hang: 0,
      ten_ngan_hang: '',
      so_tai_khoan: '',
      ten_tai_khoan: '',
      dia_chi: '',
      list_dia_chi: [],
      file_count: 0,
      file_data: [],
      napas: true,
      bank_full: null,
      list_chi_nhanh: [],
      chi_nhanh: '',
      real_ten_ngan_hang: '',
      is_thay_doi: false,
      isShowButton:false,
    };
  }
  check_login = () => {
    const login_data = localStorage.getItem(CONST_DATA.localStorage_login);
    if (login_data == null) {
      this.props.history.push(CONST_DATA.router_sign_in);
    } else {
      this.setState({
        thong_tin_hien_tai: JSON.parse(login_data)
      });
    }
  };

  componentDidMount() {
    this.check_login();
    this.get_info_bank();
  }

  send_data = () => {
    // [
    //   { type: 'Tên ngân hàng', data: this.state.bank_full.shortname },
    //   { type: 'Chi nhánh', data: this.state.chi_nhanh },
    //   { type: 'Số tài khoản', data: this.state.so_tai_khoan },
    //   { type: 'Tên chủ tài khoản', data: ten_tai_khoan },
    //   { type: 'Địa chỉ', data: this.state.dia_chi },
    //   ...this.state.file_data
    // ]
    const ticket_detail = [
      { type: 'Ngày rút tiền', data: date_format_input(this.state.ngay_rut) },
      { type: 'Số tiền rút', data: this.state.so_tien },
      { type: 'Chi tiết yêu cầu', data: this.state.ly_do },
      { type: 'Loại rút', data: this.state.type_of_rut_tien },
      // { type: 'Tên ngân hàng', data: this.state.bank_full.shortname },
      // { type: 'Chi nhánh', data: this.state.chi_nhanh },
      // { type: 'Số tài khoản', data: this.state.so_tai_khoan },
      // { type: 'Tên chủ tài khoản', data: this.state.ten_tai_khoan },
      // { type: 'Địa bàn', data: this.state.dia_chi },
      // ...this.state.file_data
    ];
    if (this.state.is_thay_doi && this.state.bank_full) {
      ticket_detail.push({ type: 'Tên ngân hàng', data: this.state.real_ten_ngan_hang });
    }
    if (this.state.is_thay_doi && this.state.ten_tai_khoan) {
      ticket_detail.push({ type: 'Tên chủ tài khoản', data: this.state.ten_tai_khoan });
    }
    if (this.state.is_thay_doi && this.state.so_tai_khoan) {
      ticket_detail.push({ type: 'Số tài khoản', data: this.state.so_tai_khoan });
    }
    if (this.state.is_thay_doi && this.state.dia_chi) {
      ticket_detail.push({ type: 'Địa bàn', data: this.state.dia_chi });
    }
    if (this.state.is_thay_doi && this.state.chi_nhanh) {
      ticket_detail.push({ type: 'Chi nhánh', data: this.state.chi_nhanh });
    }
    ticket_detail.push(...this.state.file_data)
    let ticket_new = {
      user_id: this.state.thong_tin_hien_tai.user_profile.user_id,
      ticket_type: 'ĐỐI SOÁT',
      ticket_detail
    };
    this.props.send_data(ticket_new);
  };

  render_send = () => {
    if (this.props.loading) {
      return <CircularProgress></CircularProgress>
    }
    if (
      this.state.ngay_rut != '' &&
      this.state.ngay_rut != null &&
      this.state.so_tien != '' &&
      this.state.type_of_rut_tien != null
    ) {
      return (
        <Button
          color="primary"
          variant="contained"
          onClick={() => this.send_data()}
          disabled={this.props.disabled_btn}
          >
          Xác nhận và gửi
        </Button>
      );
    } else {
      return null;
    }
  };

  get_info_bank = () => {
    Axios.get(CONST_DATA.get_user_bank, {
      headers: { user_id: this.props.user_id, access_token: this.props.access_token }
      // headers: { user_id: 1153, access_token: 'access_token' }
    })
      .then(response => {
        const result = response.data;
        if (result.status === 'success') {
          const data = result.data;
          if (data) {
            this.setState({
              ten_tai_khoan: data.account_name,
              so_tai_khoan: data.account_number,
              ten_ngan_hang: data.bank_name,
              chi_nhanh: data.branch_name,
              dia_chi: data.location,
            })
          }
          else {
            this.setState({
              isShowButton:true
            })
            const notify = () => toast.error("Chưa có tài khoản ngân hàng.",{
              position:"top-center", 
              autoClose:3000,
            });
            notify();
            setTimeout(() => {
              this.props.history.push('/');
            },3500);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  // sua thong tin ngan hang

  search = (nameKey, myArray) => {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].data === nameKey) {
        return myArray[i];
      }
    }
  };

  handleChange = text => {
    let obj_find = this.search(text, data_type_sua_thong_tin);
    // console.log(obj_find);

    this.setState({
      Loai_tt_can_sua: text,
      Loai_tt_can_sua_type: obj_find.type
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.real_ten_ngan_hang !== this.state.real_ten_ngan_hang) {
      Axios.get(`${CONST_DATA.get_location_bank}?bank_name=${this.state.real_ten_ngan_hang}`)
        .then(response => {
          const result = response.data;
          if (result.status === 'success') {
            const list_dia_chi = result.data.map(el => {
              return el.location;
            })
            this.setState({ list_dia_chi, dia_chi: '', chi_nhanh: '' })
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
    if ((prevState.dia_chi !== this.state.dia_chi)) {
      Axios.get(`${CONST_DATA.get_branches_bank}?bank_name=${this.state.real_ten_ngan_hang}&location=${this.state.dia_chi}`)
        .then(response => {
          const result = response.data;
          if (result.status === 'success' && Array.isArray(result.data)) {
            const list_chi_nhanh = result.data.map(el => {
              return el.branch_name;
            })
            this.setState({ list_chi_nhanh, chi_nhanh: '' })
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }






  check_napas = () => {
    Axios.get(napas_link(this.state.bank_code, this.state.so_tai_khoan))
      .then(response => {
        console.log(response.data);
        const data = response.data;
        if (data.error_code == 0) {
          this.setState({ ten_tai_khoan: response.data.result.AccName });
        }
        else {
          toast.error(data.message);
        }
      })
      .catch(error => {

        console.log(error);
      });
  };

  render_kiemtra = () => {
    // console.log(this.state.bank_code);

    if (this.state.bank_code != null) {
      return (
        <Button
          style={{ marginTop: "10px" }}
          color="primary"
          variant="contained"
          onClick={() => this.check_napas()}
          style={{ marginTop: "10px" }}
        >
          Kiểm tra
        </Button>
      );
    }
  };

  delete_img = i => {
    let array_state = this.state.file_data;
    array_state.splice(i, 1);
    this.setState({
      file_data: array_state,
      file_count: this.state.file_count - 1
    });
  };

  handleChange = e => {
    e.preventDefault();
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.upload_image(file, reader.result);
    };
  };

  upload_image = (file, base64) => {
    if (file.size >= 2000000) {
      this.setState({
        error: 'File của bạn quá lớn (tối đa 2MB)'
      });
    } else if (this.state.file_count > 5) {
      this.setState({
        error: 'Số lượng file tối đa là 4'
      });
    } else {
      this.setState({
        error: null,
        file_count: this.state.file_count + 1,
        file_data: [
          ...this.state.file_data,
          {
            data: base64,
            type: file.name
          }
        ]
      });
    }
  };


  handleConfirmInfoBank = () => {
    const regex = /^[a-z -\.']+$/i;
    if (this.state.ten_tai_khoan.match(regex)) {
      this.setState({ xac_nhan_thong_tin_ngan_hang: 1 })
    }
    else {
      toast.error('Tên chủ tài khoản không hợp lệ, vui lòng nhập lại.')
    }
  }

  render_button_info_bank = () => {
    if(this.state.bank_code === '970405' || this.state.bank_code === '970400'){
      if (this.state.so_tai_khoan && this.state.ten_ngan_hang && this.state.ten_tai_khoan && this.state.chi_nhanh && this.state.dia_chi) {
        return (
          <Button
            color="black"
            variant="contained"
            onClick={() => this.handleConfirmInfoBank()}>
            Cập nhật thông tin
          </Button>
        );
      } else {
        return null;
      }
    }
    else{
      if (this.state.so_tai_khoan && this.state.ten_ngan_hang) {
        return (
          <Button
            color="black"
            variant="contained"
            onClick={() => this.handleConfirmInfoBank()}>
            Cập nhật thông tin
          </Button>
        );
      } else {
        return null;
      }
    }
   
  };


  render() {
    const { classes } = this.props;
    console.log(this.state);
    return (
      <>
        {this.state.xac_nhan_thong_tin_ngan_hang === 0 && <Card className={classes.root}>
          <CardContent>
            <Typography variant="h2" gutterBottom>
              Xác nhận thông tin tài khoản ngân hàng của bạn
            </Typography>
          </CardContent>
          <form>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Tên ngân hàng
            </Typography>
              <Typography>
                Cần là tài khoản ngân hàng Hoạt động được tại Việt Nam. Trường hợp
                người dùng sử dụng ngân hàng điện tử Timo để mục này là VPBANK
            </Typography>
              <FormControl variant="outlined" className={classes.formControl}>
                <Input
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  fullWidth
                  value={this.state.ten_ngan_hang}
                  disabled
                  style={{ color: 'black' }}
                >
                </Input>
              </FormControl>
            </CardContent>
            <Divider />
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Số tài khoản
            </Typography>
              <Typography>
                Lưu ý số tài khoản KHÔNG PHẢI là dãy số được in trên thẻ. Số tài
                khoản là dãy số được ngân hàng cung cấp khi bạn đăng ký mở tài
                khoản ngân hàng. Nếu bạn không nhớ hoặc không lưu trữ, bạn có thể
                tìm lại tại mục thông tin tài khoản trong ứng dụng internet
                banking của ngân hàng đang sử dụng hoặc ra trực tiếp ngân hàng để
                lấy lại
            </Typography>
              <FormControl variant="outlined" className={classes.formControl}>
                <Input
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  fullWidth
                  value={this.state.so_tai_khoan}
                  style={{ color: 'black' }}
                  disabled
                >
                </Input>
              </FormControl>
            </CardContent>

            <Divider />
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Tên chủ tài khoản
             </Typography>
              <FormControl variant="outlined" className={classes.formControl}>
                <Input
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  fullWidth
                  value={this.state.ten_tai_khoan}
                  style={{ color: 'black' }}
                  disabled
                >
                </Input>
              </FormControl>
            </CardContent>
            <Divider />

            {this.state.dia_chi && <CardContent>
              <Typography variant="h5" gutterBottom>
                Địa chỉ
             </Typography>
              <FormControl variant="outlined" className={classes.formControl}>
                <Input
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  fullWidth
                  value={this.state.dia_chi}
                  style={{ color: 'black' }}
                  disabled
                >
                </Input>
              </FormControl>
            </CardContent>}


            <Divider />
            {this.state.chi_nhanh && <CardContent>
              <Typography variant="h5" gutterBottom>
                Chi nhánh
             </Typography>
              <FormControl variant="outlined" className={classes.formControl}>
                <Input
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  fullWidth
                  value={this.state.chi_nhanh}
                  style={{ color: 'black' }}
                  disabled
                >
                </Input>
              </FormControl>
            </CardContent>}

            <Divider />
            <CardContent>
              <Button
                variant="contained" color="primary"

                style={{ margin: 20 }}
                onClick={() => {
                  this.setState({ xac_nhan_thong_tin_ngan_hang: 1 })
                }}
                disabled={this.state.isShowButton}
              >
                Xác nhận thông tin
              </Button>
              <Button
                variant="contained"
                color="secondary"
                style={{ backgroundColor: 'red' }}
                onClick={() => {
                  this.setState({
                    xac_nhan_thong_tin_ngan_hang: 2,
                    ten_tai_khoan: '',
                    ten_ngan_hang: '',
                    chi_nhanh: '',
                    so_tai_khoan: '',
                    dia_chi: '',
                    is_thay_doi: true
                  })
                }}
                disabled={this.state.isShowButton}
              >
                Thông tin không chính xác
              </Button>
            </CardContent>
            <Typography variant="h5" gutterBottom>
              {this.state.error_code}
            </Typography>
          </form>
        </Card>}
        {/*  */}
        {this.state.xac_nhan_thong_tin_ngan_hang === 1 && <Card className={classes.root} style={{ marginTop: '15px' }}>
          <form autoComplete="off" noValidate>
            <CardContent>
              <Typography variant="h2" gutterBottom>
                Đối soát rút tiền
            </Typography>
            </CardContent>
            <Divider />
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Loại rút tiền
            </Typography>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.type_of_rut_tien}
                  onChange={event =>
                    this.setState({ type_of_rut_tien: event.target.value })
                  }>
                  {type_of_rut_tien.map(item => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CardContent>

            <Divider />
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Số tiền rút
              </Typography>
              {/* <form className={classes.root} noValidate autoComplete="off"> */}
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
                className={classes.textField}
                value={this.state.so_tien}
                onChange={event =>
                  this.setState({ so_tien: event.target.value })
                }
              />
              {/* </form> */}
            </CardContent>
            <Divider />
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Ngày rút tiền
            </Typography>
              <form className={classes.root} noValidate autoComplete="off">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  className={classes.textField}
                  value={this.state.ngay_rut}
                  onChange={event => {
                    this.setState({ ngay_rut: event.target.value });
                  }}
                  format='DD/MM/YYYY'
                  type="date"
                />
              </form>
            </CardContent>
            <Divider />

            <CardContent>
              <Typography variant="h5" gutterBottom>
                Chi tiết yêu cầu
            </Typography>
              <form className={classes.root} noValidate autoComplete="off">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  multiline={true}
                  type="text"
                  className={classes.textField}
                  value={this.state.ly_do}
                  onChange={event => this.setState({ ly_do: event.target.value })}
                />
              </form>
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                color="black"
                variant="contained"
                onClick={() => this.props.clickBack()}>
                Quay lại
            </Button>
              {this.render_send()}
            </CardActions>
          </form>
        </Card>}
        {/*  */}
        {this.state.xac_nhan_thong_tin_ngan_hang === 2 && <Card className={classes.root}>
          <form>
            <CardContent>
              <Typography variant="h2" gutterBottom>
                Thay đổi thông tin ngân hàng
            </Typography>
            </CardContent>
            <Divider />
            <CardContent>
              <Typography variant="h4" gutterBottom>
                Lưu ý:
            </Typography>
              {data_luu_y_rut_tien.map(item => (
                <Typography>{item}</Typography>
              ))}
            </CardContent>
            <Divider />

            <CardContent>
              <Typography variant="h5" gutterBottom>
                Tên ngân hàng
            </Typography>
              <Typography>
                Cần là tài khoản ngân hàng Hoạt động được tại Việt Nam. Trường hợp
                người dùng sử dụng ngân hàng điện tử Timo để mục này là VPBANK
            </Typography>

              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  fullWidth
                  value={this.state.ten_ngan_hang}
                  onChange={event => {

                    const bank_full = list_bank.find(
                      item => item.id == event.target.value
                    );
                    this.setState({
                      napas: bank_full.bank_code != null,
                      so_tai_khoan: '',
                      ten_tai_khoan: '',
                      ten_ngan_hang: bank_full.id,
                      bank_full: bank_full,
                      bank_code: bank_full.bank_code,
                      real_ten_ngan_hang: bank_full.shortname.concat(` - ${bank_full.name}`)
                    }, () => {
                      console.log(this.state.bank_code)
                    });
                  }}
                >
                  {list_bank.map(item => (
                    <MenuItem value={item.id}>
                      {item.shortname}
                      {' - '}
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CardContent>
            <Divider />
            <Divider />
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Số tài khoản
            </Typography>
              <Typography>
                Lưu ý số tài khoản KHÔNG PHẢI là dãy số được in trên thẻ. Số tài
                khoản là dãy số được ngân hàng cung cấp khi bạn đăng ký mở tài
                khoản ngân hàng. Nếu bạn không nhớ hoặc không lưu trữ, bạn có thể
                tìm lại tại mục thông tin tài khoản trong ứng dụng internet
                banking của ngân hàng đang sử dụng hoặc ra trực tiếp ngân hàng để
                lấy lại
            </Typography>
              <form className={classes.root} noValidate autoComplete="off">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  multiline={true}
                  type="number"
                  className={classes.textField}
                  value={this.state.so_tai_khoan}
                  onChange={event =>
                    this.setState({ so_tai_khoan: event.target.value })
                  }
                />
                {this.render_kiemtra()}
              </form>
            </CardContent>
            <Divider />
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Tên chủ tài khoản
            </Typography>
              <Typography>
                Yêu cầu đúng theo quy định của ngân hàng, đúng thứ tự họ - tên,
                đầy đủ dấu cách, không có dấu ...
            </Typography>
              <form className={classes.root} noValidate autoComplete="off">
                {this.state.napas && this.state.bank_full ? (
                  <TextField
                    id="outlined-basic"
                    placeholder="TEN CHU TAI KHOAN"
                    variant="outlined"
                    fullWidth
                    disabled
                    className={classes.textField}
                    value={this.state.ten_tai_khoan}
                    onChange={event =>
                      this.setState({ ten_tai_khoan: event.target.value.toUpperCase() })
                    }
                  />
                ) : (
                  <TextField
                    id="outlined-basic"
                    placeholder="TEN CHU TAI KHOAN"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    value={this.state.ten_tai_khoan}
                    onChange={event =>
                      this.setState({ ten_tai_khoan: event.target.value.toUpperCase() })
                    }
                  />
                )}
              </form>
            </CardContent>
            <Divider />
            {(this.state.bank_code === '970405' || this.state.bank_code === '970400') && <>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Địa điểm (Trường bắt buộc nhập)
            </Typography>
                <form className={classes.root} noValidate autoComplete="off">
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    fullWidth
                    value={this.state.dia_chi}
                    onChange={event => {
                      this.setState({
                        dia_chi: event.target.value
                      });
                    }}>
                    {this.state.list_dia_chi.map(item => (
                      <MenuItem value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </form>
              </CardContent>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Chi nhánh (Trường bắt buộc nhập)
            </Typography>
                <Typography>
                  Hai trường hợp đặc biệt là Ngân hàng Nông Nghiệp và Phát triển
                  Nông thôn Việt Nam - AGRIBANK và Ngân hàng Sài gòn Công thương -
                  SGB yêu cầu thông tin trụ sở chính xác để chuyển tiền. Hai trường
                  hợp thiếu thông tin này không thể giải quyết được, người dùng của
                  2 ngân hàng này vui lòng lưu ý điền đầy đủ phần này
            </Typography>
                <form className={classes.root} noValidate autoComplete="off">
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    fullWidth
                    value={this.state.chi_nhanh}
                    onChange={event => {
                      this.setState({
                        chi_nhanh: event.target.value,
                      });
                    }}>
                    {this.state.list_chi_nhanh.map(item => (
                      <MenuItem value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </form>
              </CardContent>
            </>}

            <CardContent>
              <Typography variant="h5" gutterBottom>
                Tải hình ảnh
            </Typography>
              <Typography>
                Tải hình ảnh chứng minh bạn đã đóng tài khoản cũ, không còn sử
                dụng
            </Typography>

              {this.state.file_data.map((item, index) => (
                <Box>
                  <CardMedia
                    className={classes.media}
                    component="img"
                    alt="Contemplative Reptile"
                    width={window.innerWidth - 20}
                    objectFit="cover"
                    image={item.data}
                  />
                  <Typography variant="h5" gutterBottom>
                    Tên file: {item.type}
                  </Typography>
                  <Button
                    variant="contained"
                    color="red"
                    style={{ margin: 20 }}
                    onClick={() => this.delete_img(index)}>
                    Xóa
                </Button>
                </Box>
              ))}

              <Button
                variant="contained"
                component="label"
                style={{ margin: 20 }}>
                Upload File
              <input
                  type="file"
                  style={{ display: 'none' }}
                  onChange={e => this.handleChange(e)}
                />
              </Button>
            </CardContent>
            <Divider />

            <Typography variant="h5" gutterBottom>
              {this.state.error_code}
            </Typography>

            <CardActions>
              {/* <Button
                color="black"
                variant="contained"
                onClick={() => {
                  this.setState({ xac_nhan_thong_tin_ngan_hang: 1 })
                }}>
                Cập nhật thông tin
            </Button> */}
              {this.render_button_info_bank()}
            </CardActions>
          </form>
        </Card>}

      </>

    );
  }
}

export default withStyles(styles)(AccountDetails);
