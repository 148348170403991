import React, { Component } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Card } from '@material-ui/core';

import Main from './MainComponent';
import SuaThongTin from './SuaThongTinComponent';
import ChuyenTienComponent from './ChuyenTienComponent';
import SuaThongTinNganHang from './SuaThongTinNganHang';
import SatNhapComponent from './SatNhapComponent';
import DoiSoat from './DoiSoat';
import CONST_DATA from 'constant';
import Axios from 'axios';
import { toast } from 'react-toastify'
import { data_type_issue } from './data';
import BaoHiem from './BaoHiem';
import HoanTien from './HoanTien';
import Ekyc from './Ekyc';

const styles = theme => ({
  root: {
    padding: theme.spacing(3)
  }
});

const STEP_MAIN = 'main';
const STEP_SUA_THONG_TIN = 'main ,sua thong tin';
const STEP_CHUYEN_TIEN = 'main , chuyen tien';
const STEP_SUA_THONG_TIN_NGAN_HANG = 'main ,sua thong tin ngan hang';
const STEP_SAT_NHAP = 'main ,sat nhap';
const STEP_DOI_SOAT = 'main ,doi soat';
const STEP_BAO_HIEM = 'main ,bao hiem';
const STEP_HOAN_TIEN = 'main ,hoan tien';
const STEP_EKYC = 'main, ekyc';

class CreatIssue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step_type: STEP_MAIN,
      access_token: null,
      user_id: null,
      loading: false,
      disabled_btn:false,
    };
  }

  check_login = () => {
    const login_data = localStorage.getItem(CONST_DATA.localStorage_login);

    if (login_data == null) {
      this.props.history.push(CONST_DATA.router_sign_in);
    } else {
      let access_token = JSON.parse(login_data).access_token;
      let user_id = JSON.parse(login_data).user_profile.user_id;
      this.setState({
        access_token: access_token,
        user_id: user_id
      });

      this.check_token(user_id, access_token);
    }
  };

  check_token = (user_id, access_token) => {
    const body_req = {
      user_id: user_id,
      access_token: access_token
    };

    Axios.post(CONST_DATA.check_token, body_req)
      .then(response => {
        if (response.data.status != 'success') {
          this.props.history.push(CONST_DATA.router_sign_in);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.check_login();
  }

  selectedTypeIssue = type_issue => {
    // Sửa đổi, chỉnh sửa thông tin cá nhân
    if (type_issue == data_type_issue[0]) {
      this.setState({
        step_type: STEP_SUA_THONG_TIN
      });
    }

    // 'Tôi gặp vấn đề khi CHUYỂN TIỀN và cần đối soát thủ công',
    if (type_issue == data_type_issue[1]) {
      this.setState({
        step_type: STEP_CHUYEN_TIEN
      });
    }

    //   'Sửa đổi thông tin ngân hàng,
    if (type_issue == data_type_issue[2]) {
      this.setState({
        step_type: STEP_SUA_THONG_TIN_NGAN_HANG
      });
    }

    //   'Tôi cần SÁP NHẬP 2 tài khoản'
    if (type_issue == data_type_issue[3]) {
      this.setState({
        step_type: STEP_SAT_NHAP
      });
    }

    //   'Tôi cần đối soát tài khoản'
    if (type_issue == data_type_issue[4]) {
      this.setState({
        step_type: STEP_DOI_SOAT
      });
    }

    if (type_issue == data_type_issue[5]) {
      this.setState({
        step_type: STEP_HOAN_TIEN
      });
    }

    if (type_issue == data_type_issue[6]) {
      this.setState({
        step_type: STEP_EKYC
      });
    }
  };

  clickBack = () => {
    this.setState({
      step_type: STEP_MAIN,
    });
  };

  send_data = ticket_new => {
    let add_props = {
      access_token: this.state.access_token
    };
    this.setState({ loading: true });

    // Axios.get(CONST_DATA.check_spam + this.state.user_id.toString())
    //   .then(response => {
    //     if (response.data.status == 'success' && response.data.data == true) {
    Axios.post(CONST_DATA.add_ticket, { ...add_props, ...ticket_new })
      .then(response => {
        this.setState({ loading: false })
        if (response.data.status == 'success') {

          if (response.data.data != null) {
            this.setState({
              disabled_btn:true,
            })
            const notify = () => toast.success("Gửi yêu cầu thành công.",{
              position:"top-center", 
              autoClose:3000,
            });
            notify();
            setTimeout(() => {
              this.props.history.push(
                CONST_DATA.router_yeucau + '/' + response.data.data
              );
            },3500);
            
          }
        }
      })
      .catch(error => {
        this.setState({ loading: false })
        console.log(error);
      });
    //   } else {
    //     //spam
    //     console.log('spam');
    //   }
    // })
    // .catch(error => {
    //   console.log(error);
    // });
  };

  go_list = () => {
    this.props.history.push(CONST_DATA.router_list_yeu_cau);
  };

  renderMain = () => {
    if (this.state.step_type === STEP_MAIN) {
      return (
        <Main
          go_list={() => this.go_list()}
          clickNext={text => this.selectedTypeIssue(text)}
          send_data={ticket_new => this.send_data(ticket_new)}
        />
      );
    }
    if (this.state.step_type === STEP_SUA_THONG_TIN) {
      return (
        <SuaThongTin
          clickBack={() => this.clickBack()}
          send_data={ticket_new => this.send_data(ticket_new)}
          access_token={this.state.access_token}
          loading={this.state.loading}
          disabled_btn={this.state.disabled_btn}
        />
      );
    }
    if (this.state.step_type === STEP_CHUYEN_TIEN) {
      return (
        <ChuyenTienComponent
          clickBack={() => this.clickBack()}
          send_data={ticket_new => this.send_data(ticket_new)}
          access_token={this.state.access_token}
          loading={this.state.loading}
          disabled_btn={this.state.disabled_btn}
        />
      );
    }
    if (this.state.step_type === STEP_SUA_THONG_TIN_NGAN_HANG) {
      return (
        <SuaThongTinNganHang
          access_token={this.state.access_token}
          clickBack={() => this.clickBack()}
          send_data={ticket_new => this.send_data(ticket_new)}
          loading={this.state.loading}
          disabled_btn={this.state.disabled_btn}
        />
      );
    }
    if (this.state.step_type === STEP_SAT_NHAP) {
      return (
        <SatNhapComponent
          access_token={this.state.access_token}
          clickBack={() => this.clickBack()}
          send_data={ticket_new => this.send_data(ticket_new)}
          disabled_btn={this.state.disabled_btn}
          loading={this.state.loading}
        />
      );
    }
    if (this.state.step_type === STEP_DOI_SOAT) {
      return (
        <DoiSoat
          access_token={this.state.access_token}
          clickBack={() => this.clickBack()}
          send_data={ticket_new => this.send_data(ticket_new)}
          user_id={this.state.user_id}
          history={this.props.history}
          disabled_btn={this.state.disabled_btn}
          loading={this.state.loading}
        />
      );
    }

    if (this.state.step_type === STEP_BAO_HIEM) {
      return (
        <BaoHiem
          access_token={this.state.access_token}
          clickBack={() => this.clickBack()}
          send_data={ticket_new => this.send_data(ticket_new)}
          disabled_btn={this.state.disabled_btn}
          loading={this.state.loading}
        />
      );
    }

    if (this.state.step_type === STEP_HOAN_TIEN) {
      return (
        <HoanTien
          access_token={this.state.access_token}
          clickBack={() => this.clickBack()}
          send_data={ticket_new => this.send_data(ticket_new)}
          disabled_btn={this.state.disabled_btn}
          loading={this.state.loading}
        />
      );
    }
    if (this.state.step_type == STEP_EKYC) {
      return (
        <Ekyc
          access_token={this.state.access_token}
          clickBack={() => this.clickBack()}
          send_data={ticket_new => this.send_data(ticket_new)}
          loading={this.state.loading}
          disabled_btn={this.state.disabled_btn}
        />
      );
    } else {
      return null;
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            {this.renderMain()}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(CreatIssue);
